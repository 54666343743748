.videocard {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content:space-between;
    transition: all .3s ease-in 0.2s;
    position: relative;
    .number {
        color: #1B1B1B;
        font-family: Impact;
        font-size: 180px;
        letter-spacing: 0;
        line-height: 200px;
        transition: all .3s ease-in 0.2s;
        text-align: center;
        width: 25%;
    }  
    .background-img {
        height: 100%;
        min-width: 75%;
        width: 75%;
        position: absolute;
        right: 0;
        transition: all .3s ease-in 0.2s;
        .thumbnail-image-allo {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center 0px;
            transition: all .3s ease-in 0.2s;
           border-radius: 10px;
        }
        .timer {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1050;
            width: 62px;
            height: 62px;
            border-top-right-radius: 10px;
            background: url("../../media/icons/Black And White.png") no-repeat;
            background-size: 100% 100%;
            box-shadow: none;
            padding-top: 10px;
            img {
                position: absolute;
                top: 3px;
                left: 50%;
                transform: translateX(-23%) translateY(0);
                width: 28px;
                height: 28px;
            }
            span {
                position: absolute;
                top: 49%;
                left: 50%;
                transform: translateX(-5%) translateY(0);
                flex-shrink: 0;
                color: rgba(255, 255, 255, 0.9);
                font-family: "Montserrat";
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                
            }
           
        }
        .new-text{
            position: absolute;
            top: 20%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1050;
            background-color: rgb(251, 251, 251, 0.8);
            border-radius: 24px;
            border: none;
            //min-width: 218px;
            padding: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                color: #6ebab5;
                text-align: center;
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: 25px;
            }
        }
        .video-card-layout{
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 35%;
            background: rgba(0, 0, 0, 0.40);;
            backdrop-filter: blur(75px); 
            border-radius: 0 0 10px 10px;
            .text-position {
                position: absolute;
                top: 50%;
                transform: translateX(0%) translateY(-50%); 
                left: 0;
                max-width: 95%;
                .title{
                    padding-left: 10px;
                    color: rgba(255, 255, 255, 0.90);
                    text-shadow: 8px 4px 7px rgba(67, 67, 67, 0.60); 
                    display: flex;
                    justify-content: flex-start;
                    font-family: 'Montserrat';
                    font-size: 13px;
                    font-style: italic;
                    font-weight: 700;
                    line-height: 123%;
                    letter-spacing: 1px; 
                    transition: all .3s ease-in 0.7s;
                    &.pink {
                        color: rgba(242, 150, 228, 0.95);
                    }
                    &.green {
                        color: rgba(145, 231, 225, 0.95);
                    }
                    &.purple {
                        color: rgba(167, 145, 231, 0.95);
                    }
                   
                }
            }
        }
        .description-hover {
            position: absolute;
            bottom: 0;
            opacity: 0.7;
            backdrop-filter: blur(75px);
            height: 0;
            width: 100%;
            background-color: #000000;
            padding-left: 10px;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            transition: all .3s ease-in 0.2s;
            z-index: 50;
            @media (max-width: 995.98px) {
                padding-left: 10px;
            }
            .play-button {
                display: flex;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: transparent;
                border: none;
                box-shadow: none;
                padding: 0;
                margin: 0;
                opacity: 0;
                transition: all .3s ease-in 0.2s;
                img {
                    height: 35px;
                    width: 35px;
                }
                
            }
            .time {
                align-items: center;
                justify-content: flex-start;
                display: flex;
                padding-left: 10px;
                opacity: 0;
                transition: all .3s ease-in 0.2s;
                @media (max-width: 995.98px) {
                    margin-bottom: 20px;
                }
                img {
                    height: 15px;
                    width: 15px;
                    margin-right: 5px;
                }
                span {
                    color: #FFFFFF;
                    font-family: "Montserrat";
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 34px;
                    text-align: center;
                }
            }
            
            .bookmark-div {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 2px;
                .more-text {
                    opacity: 0;
                    transition: all .3s ease-in 0.2s;
                    max-width: 100%;
                    .title-2{
                        padding-left: 10px;
                        color: rgba(255, 255, 255);
                        text-shadow: 8px 4px 7px rgba(67, 67, 67, 0.60); 
                        display: flex;
                        justify-content: flex-start;
                        font-family: 'Montserrat';
                        font-size: 16px;
                        font-style: italic;
                        font-weight: 700;
                        line-height: 123%;
                        letter-spacing: 1px; 
                        transition: all .3s ease-in 0.7s;
                    }
                    @media (max-width: 995.98px) {
                        font-size: 26px;
                        line-height: 110%;
                        width: 100%;
                    }
                }
                .bookmark-button {
                    display: flex;
                    align-items: center;
                    opacity: 0;
                    transition: all .3s ease-in 0.2s;
                    background: transparent;
                    box-shadow: none;
                    border: none;
                    padding: 0;
                    height: 48px;
                    margin-right: 25px;
                    position: relative;
                    z-index: 2500;
                    img {
                        height: 22px;
                    }
                }
            }
            
        }
        .video-time{
            margin-left: 10px;
            display: flex;
            width: 167px;
            height: 30px;
            justify-content: flex-start;
            color: #FFF;
            font-family: 'Montserrat';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px; /* 56.105px */ 
        }
    }
    &:hover {
        transform: scale(1.03);
        transition: all .3s ease-in 0.2s;
        .number {
            color: #1B1B1B;
            text-shadow: -10px 10px 15px rgba(251, 251, 251, 0.3), 0 0 2px rgba(251, 251, 251, 1),
                0 0 2px rgba(251, 251, 251, 1), 0 0 2px rgba(251, 251, 251, 1);
            transition: all .3s ease-in 0.2s;
        }
        .background-img {
            .description-hover {
                height: 100%;
                transition: all .3s ease-in 0.4s;
                .trainer-div {
                    opacity: 1;
                    transition: all .3s ease-in 0.7s;
                }
                .play-button {
                    transition: all .3s ease-in 0.8s;
                    opacity: 1;
                }
                .time {
                    opacity: 1;
                    transition: all .3s ease-in 0.8s;
                    img {
                        height: 15px;
                        width: 15px;
                        margin-right: 5px;
                    }
                }
                
                .bookmark-div {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .more-text {
                        opacity: 1;
                        transition: all .3s ease-in 0.8s;
                    }
                    .bookmark-button {
                        opacity: 1;
                        transition: all .3s ease-in 0.8;
                    }
                }
                
            }
            .title{
                opacity: 0;
                transition: all .3s ease-in 0.7s;
            }
        }
    }
   
}

.videocard-more {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .background-img {
        height: 100%;
        width: 100%;
        position: relative;
        .thumbnail-image {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center 0px;
            border-radius: 10px;
        }
      
        .timer {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1050;
            width: 62px;
            height: 62px;
            border-top-right-radius: 10px;
            background: url("../../media/icons/Black And White.png") no-repeat;
            background-size: 100% 100%;
            box-shadow: none;
            padding-top: 10px;
            img {
                position: absolute;
                top: 3px;
                left: 50%;
                transform: translateX(-23%) translateY(0);
                width: 28px;
                height: 28px;
            }
            span {
                position: absolute;
                top: 49%;
                left: 50%;
                transform: translateX(-5%) translateY(0);
                flex-shrink: 0;
                color: rgba(255, 255, 255, 0.9);
                font-family: "Montserrat";
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                
            }
           
        }
        .new-text{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1050;
            background-color: rgb(251, 251, 251, 0.8);
            border-radius: 24px;
            border: none;
            //min-width: 218px;
            padding: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                color: #6ebab5;
                text-align: center;
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: 25px;
            }
        }
        .video-card-layout{
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 35%;
            background: rgba(0, 0, 0, 0.40);;
            backdrop-filter: blur(75px); 
            border-radius: 0 0 10px 10px;
            .gymflix-logo {
                position: absolute;
                top:50%;
                transform: translateY(-50%);
                right:9px;
                width: 41px;
            }
            .text-position {
                position: absolute;
                top: 50%;
                transform: translateX(0%) translateY(-50%); 
                left: 0;
                max-width: 90%;
                .title{
                    padding-left: 10px;
                    color: rgba(255, 255, 255, 0.90);
                    text-shadow: 8px 4px 7px rgba(67, 67, 67, 0.60); 
                    display: flex;
                    justify-content: flex-start;
                    font-family: 'Montserrat';
                    font-size: 13px;
                    font-style: italic;
                    font-weight: 700;
                    line-height: 123%;
                    letter-spacing: 1px; 
                    transition: all .3s ease-in 0.7s;
                    &.pink {
                        color: rgba(242, 150, 228, 0.95);
                    }
                    &.green {
                        color: rgba(145, 231, 225, 0.95);
                    }
                    &.purple {
                        color: rgba(167, 145, 231, 0.95);
                    }
                   
                }
                &.logout {
                    max-width: 80%;
                    .title {
                        font-size: 12px;
                    }
                }
            }
        }
        .description-hover {
            position: absolute;
            bottom: 0;
            opacity: 0.7;
            backdrop-filter: blur(75px);
            height: 0;
            width: 100%;
            background-color: #000000;
            padding-left: 10px;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            transition: all .3s ease-in 0.2s;
            z-index: 50;
            @media (max-width: 995.98px) {
                padding-left: 10px;
            }
            .play-button {
                display: flex;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: transparent;
                border: none;
                box-shadow: none;
                padding: 0;
                margin: 0;
                opacity: 0;
                transition: all .3s ease-in 0.2s;
                img {
                    height: 35px;
                    width: 35px;
                }
                
            }
            .time {
                align-items: center;
                justify-content: flex-start;
                display: flex;
                padding-left: 10px;
                opacity: 0;
                transition: all .3s ease-in 0.2s;
                @media (max-width: 995.98px) {
                    margin-bottom: 20px;
                }
                img {
                    height: 15px;
                    width: 15px;
                    margin-right: 5px;
                }
                span {
                    color: #FFFFFF;
                    font-family: "Montserrat";
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 34px;
                    text-align: center;
                }
            }
            
            .bookmark-div {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 2px;
                .more-text {
                    opacity: 0;
                    transition: all .3s ease-in 0.2s;
                    max-width: 100%;
                    .title-2{
                        padding-left: 10px;
                        color: rgba(255, 255, 255);
                        text-shadow: 8px 4px 7px rgba(67, 67, 67, 0.60); 
                        display: flex;
                        justify-content: flex-start;
                        font-family: 'Montserrat';
                        font-size: 16px;
                        font-style: italic;
                        font-weight: 700;
                        line-height: 123%;
                        letter-spacing: 1px; 
                        transition: all .3s ease-in 0.7s;
                    }
                    @media (max-width: 995.98px) {
                        font-size: 26px;
                        line-height: 110%;
                        width: 100%;
                    }
                }
                .bookmark-button {
                    display: flex;
                    align-items: center;
                    opacity: 0;
                    transition: all .3s ease-in 0.2s;
                    background: transparent;
                    box-shadow: none;
                    border: none;
                    padding: 0;
                    height: 48px;
                    margin-right: 25px;
                    position: relative;
                    z-index: 2500;
                    img {
                        height: 22px;
                    }
                }
            }
            
        }
        .video-time{
            margin-left: 10px;
            display: flex;
            width: 167px;
            height: 30px;
            justify-content: flex-start;
            color: #FFF;
            font-family: 'Montserrat';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px; /* 56.105px */ 
        }
    }
    &:hover {
        .background-img {
          
            .description-hover {
                height: 100%;
                transition: all .3s ease-in 0.4s;
                .trainer-div {
                    opacity: 1;
                    transition: all .3s ease-in 0.7s;
                }
                .play-button {
                    transition: all .3s ease-in 0.8s;
                    opacity: 1;
                }
                .time {
                    opacity: 1;
                    transition: all .3s ease-in 0.8s;
                    img {
                        height: 15px;
                        width: 15px;
                        margin-right: 5px;
                    }
                }
                
                .bookmark-div {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .more-text {
                        opacity: 1;
                        transition: all .3s ease-in 0.8s;
                    }
                    .bookmark-button {
                        opacity: 1;
                        transition: all .3s ease-in 0.8;
                    }
                }
                
            }
            .title{
                opacity: 0;
                transition: all .3s ease-in 0.7s;
            }
        }
    }
    @media (min-width: 1600px) and (max-width: 1919.98px) { 
        .videocard-more {
            height: 452px;
            width: 100%;
            .background-img {
                .description {
                    height: 80px;
                    width: 100%;
                    padding-left: 10px;
                    .trainer-div {
                        top: 10px;
                        left: 10px;
                        img {
                            height: 50px;
                            width: 50px;
                            margin-right: 10px;
                        }
                        span {
                            font-size: 16px;
                            line-height: 20px;
                        }
                    }
                    button {
                        img {
                            height: 50px;
                            width: 50px;
                        }
                        
                    }
                    .time {
                        margin-bottom: 0x;
                        img {
                            height: 18px;
                            width: 18px;
                            margin-right: 8px;
                        }
                        span {
                            font-size: 20px;
                            line-height: 20px;
                        }
                    }
                    .less-text {
                        font-size: 18px;
                        line-height: 22px;
                        margin-bottom: 19.35px;
                    }
                }
            }
            &:hover {
                .background-img {
                    .description {
                        .more-text {
                            font-size: 18px;
                            line-height: 20px;
                            margin-bottom: 19.35px;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

.videocard-respo {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content:space-between;
    transition: all .3s ease-in 0.2s;
    position: relative;
    .number {
        span {
            color: #1B1B1B;
            font-family: Impact;
            font-size: 120px;
            letter-spacing: 0;
            text-shadow: -10px 10px 15px rgba(251, 251, 251, 0.3), 0 0 2px rgba(251, 251, 251, 1),
             0 0 2px rgba(251, 251, 251, 1), 0 0 2px rgba(251, 251, 251, 1);
        }
        width: 40px;
        
    }  
    .background-img {
        //height: 100%;
        min-width: 75%;
        width: 75%;
        position: absolute;
        right: 0;
        transition: all .3s ease-in 0.2s;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center 0px;
            transition: all .3s ease-in 0.2s;
            border-radius: 5px;
        }
        .more-text {
            position: absolute;
            z-index: 10;
            justify-content:center;
            width: 80%;
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);
            color: #ffffff;
            font-size: 16px;
            font-family: "Montserrat";
            letter-spacing: 0;
            line-height: 110%;
            display: flex;
            text-align: center;
        }
    }
}

.videocard-more-respo {
    //height: 180px;
    width: 100%;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content:space-between;
    transition: all .3s ease-in 0.2s;  
    .background-img {
        height: 100%;
        width: 100%;
        position: relative;
        transition: all .3s ease-in 0.2s;
        
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center 0px;
            transition: all .3s ease-in 0.2s;
            border-radius: 5px;
           
        }
        .more-text {
            position: absolute;
            z-index: 10;
            justify-content:center;
            width: 80%;
            bottom: 5px;
            left: 50%;
            transform: translateX(-50%);
            color: #ffffff;
            font-size: 16px;
            font-family: "Montserrat";
            letter-spacing: 0;
            line-height: 110%;
            display: flex;
            text-align: center;
        }
    }
}
.trainercard-respo {
    height: 210px;
    max-width: 100%;
    cursor: pointer;
    .background-img {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 0;
        img {
            max-height: 200px;
            max-width: 200px;
            height: 90%;
            width: 90%;
            border-radius: 50%;;
        }
        .title {
            width: 80%;
            display: flex;
            justify-content: center;
            margin-top: 20px;
            span {
                color: #FFFFFF;
                font-size: 24px;
                letter-spacing: 0;
                line-height: 110%;
                text-align: center;
            }
            
        }
        
    }
   
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  
}

// Small devices (landscape phones, less than 768px)
@media (min-width: 576px) and (max-width: 767.98px) {
   
}

// Medium devices (tablets, less than 992px)
@media (min-width: 768px) and (max-width: 991.98px) {
   
}

// Large devices (desktops, less than 1200px)
@media (min-width: 992px) and (max-width: 1199.98px) {

}
/*
// Large devices (desktops, less than 1600px)
@media (min-width: 1080px) and (max-width: 1249.98px) {
    .videocard {
        height: 280px;
        width: 275px;
        .number {
            font-size: 160px;
            line-height: 160px;
        }  
        .background-img {
            height: 100%;
            width: 175px;
            img {
                height: 100%;
                width: 100%;
               
            }
            .description {
                height: 60px;
                width: 100%;
                padding-left: 10px;
                .trainer-div {
                    top: 10px;
                    left: 10px;
                    img {
                        height: 50px;
                        width: 50px;
                        margin-right: 10px;
                    }
                    span {
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
                button {
                    img {
                        height: 50px;
                        width: 50px;
                    }
                    
                }
                .time {
                    margin-bottom: 0x;
                    img {
                        height: 18px;
                        width: 18px;
                        margin-right: 8px;
                    }
                    span {
                        font-size: 20px;
                        line-height: 20px;
                    }
                }
                .less-text {
                    font-size: 18px;
                    line-height: 22px;
                    margin-bottom: 19.35px;
                }
            }
        }
        &:hover {
            .background-img {
                .description {
                    .more-text {
                        font-size: 18px;
                        line-height: 20px;
                        margin-bottom: 19.35px;
                        opacity: 1;
                    }
                }
            }
        }
    }
    .videocard-more {
        height: 362px;
        width: 100%;
        .background-img {
            .description {
                height: 90px;
                width: 100%;
                padding-left: 10px;
                .trainer-div {
                    top: 10px;
                    left: 10px;
                    img {
                        height: 50px;
                        width: 50px;
                        margin-right: 10px;
                    }
                    span {
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
                button {
                    img {
                        height: 50px;
                        width: 50px;
                    }
                    
                }
                .time {
                    margin-bottom: 0x;
                    img {
                        height: 18px;
                        width: 18px;
                        margin-right: 8px;
                    }
                    span {
                        font-size: 20px;
                        line-height: 20px;
                    }
                }
                .less-text {
                    font-size: 18px;
                    line-height: 22px;
                    margin-bottom: 19.35px;
                }
            }
        }
        &:hover {
            .background-img {
                .description {
                    .more-text {
                        font-size: 18px;
                        line-height: 20px;
                        margin-bottom: 19.35px;
                        opacity: 1;
                    }
                }
            }
        }
    }
}

// Large devices (desktops, less than 1600px)
@media (min-width: 1250px) and (max-width: 1399.98px) {
    .videocard {
        height: 310px;
        width: 320px;
        .number {
            font-size: 180px;
            line-height: 180px;
        }  
        .background-img {
            height: 100%;
            width: 220px;
            img {
                height: 100%;
                width: 100%;
               
            }
            .description {
                height: 60px;
                width: 100%;
                padding-left: 10px;
                .trainer-div {
                    top: 10px;
                    left: 10px;
                    img {
                        height: 50px;
                        width: 50px;
                        margin-right: 10px;
                    }
                    span {
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
                button {
                    img {
                        height: 50px;
                        width: 50px;
                    }
                    
                }
                .time {
                    margin-bottom: 0x;
                    img {
                        height: 18px;
                        width: 18px;
                        margin-right: 8px;
                    }
                    span {
                        font-size: 20px;
                        line-height: 20px;
                    }
                }
                .less-text {
                    font-size: 18px;
                    line-height: 22px;
                    margin-bottom: 19.35px;
                }
            }
        }
        &:hover {
            .background-img {
                .description {
                    .more-text {
                        font-size: 18px;
                        line-height: 20px;
                        margin-bottom: 19.35px;
                        opacity: 1;
                    }
                }
            }
        }
    }
    .videocard-more {
        height: 382px;
        width: 100%;
        .background-img {
            .description {
                height: 80px;
                width: 100%;
                padding-left: 10px;
                .trainer-div {
                    top: 10px;
                    left: 10px;
                    img {
                        height: 50px;
                        width: 50px;
                        margin-right: 10px;
                    }
                    span {
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
                button {
                    img {
                        height: 50px;
                        width: 50px;
                    }
                    
                }
                .time {
                    margin-bottom: 0x;
                    img {
                        height: 18px;
                        width: 18px;
                        margin-right: 8px;
                    }
                    span {
                        font-size: 20px;
                        line-height: 20px;
                    }
                }
                .less-text {
                    font-size: 18px;
                    line-height: 22px;
                    margin-bottom: 19.35px;
                }
            }
        }
        &:hover {
            .background-img {
                .description {
                    .more-text {
                        font-size: 18px;
                        line-height: 20px;
                        margin-bottom: 19.35px;
                        opacity: 1;
                    }
                }
            }
        }
    }
}

// Large devices (desktops, less than 1600px)
@media (min-width: 1400px) and (max-width: 1599.98px) {
    .videocard {
        height: 360px;
        width: 370px;
        .number {
            font-size: 200px;
            line-height: 200px;
        }  
        .background-img {
            height: 100%;
            width: 260px;
            img {
                height: 100%;
                width: 100%;
               
            }
            .description {
                height: 60px;
                width: 100%;
                padding-left: 20px;
                .trainer-div {
                    top: 20px;
                    left: 20px;
                    img {
                        height: 60px;
                        width: 60px;
                        margin-right: 10px;
                    }
                    span {
                        font-size: 20px;
                        line-height: 20px;
                    }
                }
                button {
                    img {
                        height: 60px;
                        width: 60px;
                    }
                    
                }
                .time {
                    margin-bottom: 0x;
                    img {
                        height: 20px;
                        width: 20px;
                        margin-right: 10px;
                    }
                    span {
                        font-size: 22px;
                        line-height: 22px;
                    }
                }
                .less-text {
                    font-size: 20px;
                    line-height: 22px;
                    margin-bottom: 19.35px;
                }
            }
        }
        &:hover {
            .background-img {
                .description {
                    .more-text {
                        font-size: 20px;
                        line-height: 22px;
                        margin-bottom: 19.35px;
                        opacity: 1;
                    }
                }
            }
        }
    }
    .videocard-more {
        height: 412px;
        width: 100%;
        .background-img {
            .description {
                height: 80px;
                width: 100%;
                padding-left: 10px;
                .trainer-div {
                    top: 10px;
                    left: 10px;
                    img {
                        height: 50px;
                        width: 50px;
                        margin-right: 10px;
                    }
                    span {
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
                button {
                    img {
                        height: 50px;
                        width: 50px;
                    }
                    
                }
                .time {
                    margin-bottom: 0x;
                    img {
                        height: 18px;
                        width: 18px;
                        margin-right: 8px;
                    }
                    span {
                        font-size: 20px;
                        line-height: 20px;
                    }
                }
                .less-text {
                    font-size: 18px;
                    line-height: 22px;
                    margin-bottom: 19.35px;
                }
            }
        }
        &:hover {
            .background-img {
                .description {
                    .more-text {
                        font-size: 18px;
                        line-height: 20px;
                        margin-bottom: 19.35px;
                        opacity: 1;
                    }
                }
            }
        }
    }
}

// X-Large devices (large desktops, less than 1400px)
*/