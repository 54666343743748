

.hero {
    //margin-top: -59px;
    
    @media (max-width: 991.98px) {
    margin-top: 121.6px;
    }
    @media (max-width: 444.98px) {
        margin-top: 139.6px;
    }
    width: 100%;
    background-color: transparent;
    position: relative;
    z-index: 1;
    &.loggedin {
        @media (max-width: 991.98px) {
            margin-top: 82px;
            
          }
    }

    &.live {
        @media (max-width: 991.98px) {
            margin-top: 170px;
            
          }
    }
    
    .backgroundImage-style {
        width: 100%;
        object-fit: cover;
        object-position: center 0px;
        
    }

    
}

.nyeremeny-jatek {
    
    overflow: hidden;
    width: 100%;
    .jatek-block {
        position: relative;
        display: flex;
        justify-content:center;
        flex-direction: column;
        align-items: center;
        width: 100%;
        .jatek-div {
            position: relative;
            display: flex;
            justify-content:center;
            flex-direction: column;
            align-items: center;
            width: 100%;
            .mobile-image {
                max-width: 275px;
                height: 467px;
                width: 100%;
            }
            .nyeremeny-video {
                position: absolute;
                bottom: 26px;
                left: 50%;
                transform: translateX(-49.5%);
                width: 230px;
                height: 415px;
                z-index: 0;
                border-radius: 27px;
                overflow: hidden;
                iframe {
                    width: 100%;
                    height: 100%;
                }
                .player {
                    max-width: unset;
                    height: calc(173.913vw);
                }
            }
        }
       
        .nyeremeny-text {
            width: 100%;
           
        }
    }
    
}

.videotar {
    background: linear-gradient(180deg,transparent 0,#000 7%);
        margin-top: -290px;
        padding-bottom: 30px;
        position: relative;
        z-index:10;
        @media (max-width: 991.98px) {
            margin-top: -158px;
          }
    &.isLogedIn-false {
        background: lightgray 50% !important;
    }
    
    .motivation-video {
        height: 610px;
        @media (max-width: 767.98px) {
            height: 451px; 
          }
        width: 100%;
        background: rgba(36, 36, 36, 0.90); 
        position: relative;
        margin-top: 157px;
        padding-bottom: 80px;
        .motivacio-background{
            position: absolute;
            right: 0px;
            top: 50%;
            @media (max-width: 767.98px) {
                width: 100%;
                height: auto; 
              }
            -webkit-transform: translateX(0%) translateY(-50%);
            transform: translateX(0%) translateY(-50%); 
            width:799px;
            height: 428px;
        }
        .motivatio-title {
            padding-top: 63px;
            @media (max-width: 767.98px) {
                padding-top: 20px;
              }
            span {
                color: #FFF;
                font-family: "Montserrat";
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.45px;
            }
        }
        .video-content {
            border-radius: 10px !important;
            width: 653px;
            @media (max-width: 767.98px) {
                width: 100%;
                height: 240px; 
              }
            height: 374px; 
            box-shadow: none;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 500;
    
        }
        
    }
    .video-item-card {
        position: relative;
        z-index: 1000;
        width: 100%;
        //max-width: 286px;
        height: 185px;
        border-radius: 10px;
        margin: 15px 0;
        transform: scale(1);
        transition: all .5s ease-in-out 0.5s;
        cursor: pointer;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background-position: center top !important;
        overflow: hidden;
        .timer {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1050;
            width: 62px;
            height: 62px;
            //border-radius: 50%;
            background: url("../../media/icons/Black And White.png") no-repeat;
            background-size: 100% 100%;
            //background-color: transparent;
            //background-color: rgba(211, 211, 211);
            box-shadow: none;
            padding-top: 10px;
            img {
                position: absolute;
                top: 3px;
                left: 50%;
                transform: translateX(-23%) translateY(0);
                width: 28px;
                height: 28px;
            }
            span {
                position: absolute;
                top: 49%;
                left: 50%;
                transform: translateX(-5%) translateY(0);
                flex-shrink: 0;
                color: rgba(255, 255, 255, 0.9);
                font-family: "Montserrat";
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                //line-height: normal; 
            }
           /* &.pink {
                span {
                    color: rgba(242, 150, 228);
                }
            }
            &.green {
                span {
                    color: rgba(145, 231, 225);
                }
            }
            &.purple {
                span {
                    color: rgba(167, 145, 231);
                }
            }*/
        }
        .promo-ikon {
            position: absolute;
            top: 10px;
            left: 10px;
            z-index: 1050;
            

        }
        .new-text{
            position: absolute;
            top: 20%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1050;
            background-color: rgb(251, 251, 251, 0.8);
            border-radius: 24px;
            border: none;
            //min-width: 218px;
            padding: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                color: #6ebab5;
                text-align: center;
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: 25px;
            }
        }
        .title{
            padding-left: 10px;
            color: rgba(255, 255, 255, 0.90);
            text-shadow: 8px 4px 7px rgba(67, 67, 67, 0.60); 
            display: flex;
            //width: 95%;
            //height: 61px;
            justify-content: flex-start;
            font-family: 'Montserrat';
            font-size: 14px;
            font-style: italic;
            font-weight: 700;
            line-height: 123%;
            letter-spacing: 1px; 
            &.pink {
                color: rgba(242, 150, 228, 0.95);
            }
            &.green {
                color: rgba(145, 231, 225, 0.95);
            }
            &.purple {
                color: rgba(167, 145, 231, 0.95);
            }
        }
        .title-2{
            padding-left: 10px;
            color: rgba(255, 255, 255, 0.90);
            text-shadow: 8px 4px 7px rgba(67, 67, 67, 0.60); 
            display: flex;
            width: 100%;
            //height: 61px;
            justify-content: flex-start;
            font-family: 'Montserrat';
            font-size: 12px;
            font-style: italic;
            font-weight: 700;
            line-height: 123%;
            letter-spacing: 1px; 
            &.pink {
                color: rgba(242, 150, 228, 0.95);
            }
            &.green {
                color: rgba(145, 231, 225, 0.95);
            }
            &.purple {
                color: rgba(167, 145, 231, 0.95);
            }
        }
        .video-time{
            margin-left: 10px;
            display: flex;
            width: 167px;
            height: 30px;
            justify-content: flex-start;
            color: #FFF;
            font-family: 'Montserrat';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px; /* 56.105px */ 
        }
        .video-card-layout{
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 35%;
            background: rgba(0, 0, 0, 0.40);;
            backdrop-filter: blur(75px); 
            border-radius: 0 0 10px 10px;
            .alakreform-icon {
                position: absolute;
                top: 10px;
                right: 6px;
                width: 55px;
                height: auto;
            }
            .text-position {
                position: absolute;
                top: 50%;
                transform: translateX(0%) translateY(-50%); 
                left: 0;
                max-width: 95%
            }
        }
        
    }
    .videotar-title {
        width: 100%;
        padding-top: 70px;
        padding-bottom: 50px;
        span {
            color: #232323;
            text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            font-family: 'Montserrat';
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.45px; 
           
        }
        &.visible-false {
            opacity: 0;
            display: none;
            padding: 0;
        }
    }
    .category-title {
        color: #e5e5e5;
        font-family: "Montserrat";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.45px;
        padding-left: 0;
        background-color: transparent;
        border: none;
        margin: 0 0 20px 100px;
        @media (max-width: 991.98px) {
            font-size: 16px;
            margin: 0 0 20px 20px;
          }
       
        .link-text {
            color: rgb(145, 231, 225);
            font-size: 20px;
            opacity: 0;
            transition: all 0.6s ease-in-out;
            display: inline-flex;
            margin-left: 5px;
            align-items: center;
           // margin-left: 10px;
            &::after {
                content: "\203A";
                font-size: 38px;
                height: 38px;
                margin-top: -15px;
                margin-left: 5px;
            }
        }

        &:hover {
            .link-text {
                transform: translate(20px);
                opacity: 1;
                width: 300px;
                font-size: 20px;
            }
        }
    }
    .video-col {
        position: relative;
        margin: 0 5px;

        .touchbtn {
            height: 150px;
            width: 400px;
            margin-top: -80px;
            border-color: seashell;
            background-color: seashell;
            transform: translateX(-200px);
        }
        
        .videoTrap-position {
            position: absolute;
            top: -105px;
            left: -35px;

            transform: scale(.5);
            @media (min-width: 992px) {
                transform: scale(0);
            }
            z-index: 3500;
            opacity: 0;
            transition: all .4s ease-in-out 0.2s;
            cursor: pointer;
           
            .videoTrap {
                position: relative;
                width: 383px;
                height: 353px;
                border-radius: 10px;
                z-index: 3500;
                border: 1px solid #FFF;
                background: #171717;
                box-shadow: 0px 4px 16px 2px rgba(0, 0, 0, 0.25);
                .timer {
                    position: absolute;
                    top: 15px;
                    right: 15px;
                    z-index: 1500;
                    width: 62px;
                    height: 62px;
                    border-radius: 50%;
                    background: #FFFFFF;
                    padding-top: 10px;
                    img {
                        width: 25px;
                        height: 25px;
                    }
                    &.pink {
                        background: rgba(242, 150, 228, 0.95);
                    }
                    &.green {
                        background: rgba(145, 231, 225, 0.95);
                    }
                    &.purple {
                        background: rgba(167, 145, 231, 0.95);
                    }
                }
                .videoitem-image {
                    position: absolute;
                    width: 363px;
                    height: auto;
                    border-radius: 10px;
                    margin: 10px 10px; 
                    z-index: 1000;
                }
                .videoitem-promo {
                    position: absolute;
                    border-radius: 10px;
                    margin: 10px 10px; 
                    z-index: 1020;
                    opacity: 0;
                    transform: scale(0);
                    transition: all .4s ease-in-out 0.3s;
                    &.show {
                        transform: scale(1);
                        opacity: 1;
                        transition: all .4s ease-in-out 0.3s;
                    }
                }
                .title{
                    display: flex;
                    height: unset;
                    justify-content: center;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.05em;
                    color: #FFFFFF;
                    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                }
                .video-time{
                    display: flex;
                    //width: 167px;
                    height: 24px;
                    justify-content: center;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 18px;
                    letter-spacing: 0.05em;
                    color: #FFFFFF;
                    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                }
                .loading-manager {
                    position: absolute !important;
                    top: 40%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                }
                .video-card-layout{
                    width: 100%;
                    height: 70px;
                    background: transparent;
                    border-radius: none;
                    justify-content: center;
                    display: grid;
                }
                .video-card-layout-kedvencek{
                    margin-top: 235px;
                    width: 100%;
                    height: 40px;
                    background: transparent;
                    border-radius: none;
                    justify-content: center;
                    display: grid;
                    button {
                        padding: 0;
                    }
                    .fa-2x {
                        font-size: 35px;
                    }
                    .video-play {
                        width: 35px;
                        height: 35px; 
                        border-radius: 50%;
                        background: #43B5AE;
                        margin-right: 10px;
                        img {
                            margin-left: 3px;
                        }
                    }
                }
            
            }
        }
        &.first-active {
            .videoTrap-position {
                left: 0;
            }
        }
        &.last-active {
            .videoTrap-position {
                right: 0;
                left: unset;
            }
        }
        @media all and (min-width: 1199.98px) {
            &:hover {
                /*.video-item-card {
                    //transform: scale(0);
                }*/
                .videoTrap-position {
                    opacity: 1;
                    transform: scale(1);    
                }
            }
        }
        
    } 
    
}

.videotar-2 {
    background: linear-gradient(180deg,transparent 0,#000 3%);
    margin-top: -200px;
    padding-top: 230px;
    padding-bottom: 30px;
    position: relative;
    z-index:10;
    @media (max-width: 991.98px) {
        margin-top: 0;
        margin-top: 200px;
        padding-top: 0;
      }
    .category-title {
        color: #e5e5e5;
        font-family: 'din-condensed';
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing:0px;
        padding-left: 0;
        background-color: transparent;
        border: none;
        margin: 0 0 20px 100px;
        @media (max-width: 991.98px) {
            margin: 25px 0 15px 20px;
        }
        .link-text {
            color: #FF557B;
            font-size: 20px;
            opacity: 0;
            transition: all 0.6s ease-in-out;
            display: inline-flex;
            margin-left: 10px;
            &::after {
                margin-left: 10px;
                margin-top: 6px;
                width: 30px;
                flex-shrink: 0;
                content: "";
                background-image: url('../../media/icons/next pink.png');
                background-size: 10px 20.35px;
                background-repeat: no-repeat;  
            }
        }

        &:hover {
            .link-text {
                transform: translate(20px);
                opacity: 1;
                width: 300px;
                font-size: 28px;
            }
        }
        @media (max-width: 991.98px) {
            font-size: 16px
        }

    }
  
}

.videodetail-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    min-height: 100vh;
    margin-top: 0;
    @media (max-width: 992px) {
        min-height: unset;
    }
            
    .black-button {
        position: sticky;
        margin-left: -95vw;
        z-index: 2500;
        left: 0;
        top: 15px;
       
        border: none;
        box-shadow: none;
        background-color: rgba(0, 0, 0, 0.25);
        display: flex;
        align-items: center;
        @media (max-width: 992px) {
            top: 90px;
            &.logout {
                top: 150px;
            }
        }
        span {
            font-size: 22px;
            letter-spacing: 0;
            line-height: 26px;
            color: #FFFFFF;
        }
        img {
            height: 18px;
            //width: 34.25px;
            margin-right: 15px;
        }
    }
    
    .first-section {
        @media (max-width: 992px) {
            margin: 54px 0 10px 0;
            &.logout {
                margin: 170px 0 10px 0;
            }
        }
        .iframe-div {
            transform: scale(0);
            opacity: 0;
            transition: all .3s ease-in;
            &.hover-effect {
                transform: scale(1);
                opacity: 1;
                transition: all .5s ease-in ;
            }
        }
    }
    .detail-section {
        margin: 20px 0 10px 0;
        padding: 0 10px;
        width: 100%;
        .video-title {
            margin: 0 0 20px 0;
            span {
                color: #FFFFFF;
                font-size: 30px;
                letter-spacing: 0;
                line-height: 110%;
                display: flex;
            }
        }
        .timer {
            align-items: center;
            justify-content: flex-start;
            display: flex;
            margin: 0 0 20px 0;
            img {
                height: 20px;
                width: 20px;
                margin-right: 10px;
            }
            span {
                color: #FFFFFF;
                font-family: 'Montserrat';
                font-size: 22px;
                letter-spacing: 0;
                line-height: 34px;
                text-align: center;
            }
        }
        .play-button {
            margin: 0 0 20px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            button {
                border: none;
                background-color: transparent;
                padding: 0;
                img {
                    width: 60px;
                }
            }
        }
        .video-description {
            margin: 0 0 20px 0;
            span {
                color: #FFFFFF;
                font-family: 'Montserrat';
                font-size: 18px;
                letter-spacing: 0;
                line-height: 110%;
                text-align: center;
            }
        }
        .trainer-div {
            margin: 0 0 20px 0;
            display: flex;
            align-items: center;
            img {
                width: 100px; 
                border-radius: 50%;
                margin-right: 15px;
            }
            span {
                color: #FFFFFF;
                font-family: 'Montserrat';
                font-size: 26px;
                letter-spacing: 0;
                line-height: 110%;
                text-align: center;
            }
        }
        .bookmark-div {
            margin: 30px 0 20px 0;
            span {
                color: #FFFFFF;
                font-family: 'Montserrat';
                font-size: 18px;
                letter-spacing: 0;
                line-height: 110%;
                text-align: center;
            }
            .bookmark-button {
                img {
                    width: 20px;
                    margin-right: 15px;
                }
            }
        }
    }
    .videolist-section {
        margin: 20px 0 10px 0;
        padding: 0 10px;
        width: 100%;
        overflow: hidden;
        .videolist-title {
            margin: 0 0 20px 0;
            span {
                color: #FFFFFF;
                font-family: 'Montserrat';
                font-size: 26px;
                letter-spacing: 0;
                line-height: 110%;
            }
            .trainer {
                display: flex;
                align-items: center;
                margin-top: 20px;
                img {
                    border-radius: 50%;
                    width: 80px;
                    margin-right: 15px;
                }
            }
        }
    }
}

.videoplay-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    min-height: 100vh;
    button {
        position: absolute;
        z-index: 2500;
        left: 20px;
        top: 100px;
        border: none;
        box-shadow: none;
        background-color: rgba(0, 0, 0, 0.25);
        display: flex;
        align-items: center;
        @media (max-width: 992px) {
            top: 90px;
        }
        span {
            font-size: 26px;
            letter-spacing: 0;
            line-height: 26px;
            color: #FFFFFF;
        }
        img {
            height: 30px;
            //width: 34.25px;
            margin-right: 20px;
        }
    }
    .first-section {
        .reactplayer-div {
            transform: scale(0);
            opacity: 0;
            transition: all .4s ease-in;
            &.hover-effect {
                transform: scale(1);
                opacity: 1;
                transition: all .5s ease-in ;
            }
        }
        .iframe-div {
            transform: scale(0);
            opacity: 0;
            transition: all .3s ease-in;
            &.hover-effect {
                transform: scale(1);
                opacity: 1;
                transition: all .5s ease-in ;
            }
        }
    }
}


.live-edzes {
    background:  rgb(0, 0, 0) ;
    padding-bottom: 80px;
    position: relative;
    //padding-top: 95px;
    .live-title {
        width: 100%;
        padding-top: 70px;
        padding-bottom: 50px;
        span {
            color: rgba(255, 255, 255, 0.75);
            text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            font-family: 'Montserrat';
            font-size: 36px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.24px; 
        }
       
    }
    .live-div{
        padding: 20px 0;
        border-radius: 20px;
        border: 1px solid #ffffff;
        background: #000000;
    }
}

    

.gyik {
    background: #F3F3F3; 
    padding-bottom: 40px;
     p {
        color: #FFF;
        text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        font-family: 'Montserrat';
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height:43px;
        letter-spacing: 0.2px; 
        margin-bottom: 0;
        @media (max-width: 767.98px) {
            font-size: 12px; 
            line-height:39px;
          }
        }
    .gyik-title {
        width: 100%;
        padding-top: 70px;
        padding-bottom: 50px;
        span {
            color: #232323;
            text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            font-family: "Montserrat";
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.45px;
        }
       
    }
    .gyik-text {
        p {
            color: #232323;
            text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            font-family: 'Montserrat';
            font-size: 0.9rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.24px; 
        }
       
    }
    .accordion-button {
        position: relative;
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        align-items: center;
        width: 100%;
        color: #FFF;
        text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        font-family: 'Montserrat';
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height:43px;
        letter-spacing: 0.2px; 
        &::after {
            visibility: hidden;
        }
    }
    .accordion-header {
        max-width: 1233px;
        height: 43px; 
        border-radius: 10px;
        background: linear-gradient(90deg, rgba(137, 204, 206, 0.891) 0%, rgba(174, 213, 218, 0.891) 100%);
    }
    .accordion-button:not(.collapsed) {
        max-width: 1233px;
        height: 43px; 
        border-radius: 10px;
        color: var(--mdb-accordion-active-color);
        background: linear-gradient(90deg, rgba(137, 204, 206, 0.891) 0%, rgba(174, 213, 218, 0.891) 100%);
        box-shadow: none;
        color: #FFF;
        text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        font-family: 'Montserrat';
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height:43px;
        letter-spacing: 0.2px; 
    }
    .accordion-body {
        max-width: 1233px; 
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.90);
        box-shadow: -2px 6px 15px 0px rgba(0, 0, 0, 0.10);

      }
      .accordion-item {
        margin-bottom: 5px;
        
      } 
}

/*.videotar:hover{
        background-color: #696a6a;
        opacity: 1;
        transition: all .5s ease-in-out 0.5s;
        z-index: 1000;
       
} */

dialog::backdrop{
    background: rgba(15, 15, 15, 0.60) !important;
}

dialog {
    &#favDialog {
        border-radius: 20px;
        width: calc(130vh);
        border: 1px solid #ffffff;
        background: #000000;
        .video-description-dialog {
            color: rgb(145, 231, 225, 0.9);
            font-size: 20px;
        }
    }
    &#favDialog_live {
        border-radius: 20px;
        width: calc(130vh);
        border: 1px solid #ffffff;
        background: #000000;
    }
    
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  
}

// Small devices (landscape phones, less than 768px)
@media (min-width: 576px) and (max-width: 767.98px) {
   
}

// Medium devices (tablets, less than 992px)
@media (min-width: 768px) and (max-width: 991.98px) {
   
}

// Large devices (desktops, less than 1200px)
@media (min-width: 992px) and (max-width: 1199.98px) {
    .videotar {
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 12%);
        padding-bottom: 80px;
        position: relative;
        margin-top: -95px;
    
        &.isLogedIn-false {
            background: lightgray 50% !important;
        }
        
        .motivation-video {
            height: 610px;
            width: 100%;
            background: rgba(36, 36, 36, 0.90); 
            position: relative;
            margin-top: 157px;
            padding-bottom: 80px;
            .motivacio-background{
                position: absolute;
                right: 0px;
                top: 50%;
                -webkit-transform: translateX(0%) translateY(-50%);
                transform: translateX(0%) translateY(-50%); 
                width:799px;
                height: 428px;
            }
            .motivatio-title {
                padding-top: 63px;
                span {
                    color: #FFF;
                    text-align: center;
                    font-family: 'Montserrat';
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 45px;
                    letter-spacing: 1.5px; 
                }
            }
            .video-content {
                border-radius: 10px !important;
                width: 653px;
                height: 374px; 
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 500;
        
            }
            
        }
        .video-item-card {
            &.white-border{
                border: solid 2px #ffffff;
            }
            
        }
        .videotar-title {
            width: 100%;
            padding-top: 70px;
            padding-bottom: 50px;
            span {
                color: #232323;
                text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                font-family: Montserrat;
                font-size: 45px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: 0.45px; 
                &.visible-false {
                    opacity: 0;
                }
            }
        }
        .category-title {
            color: #e5e5e5;
            font-family: "Montserrat";
            font-size: 30px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.45px;
            padding-left: 25px;
            background-color: transparent;
            border: none;
           
            .link-text {
                color: #54b9c5;
                font-family: "Montserrat";
                font-size: 20px;
                opacity: 0;
                transition: all 0.6s ease-in-out;
                display: inline-flex;
                margin-left: 10px;
                &::after {
                    content: "\203A";
                    font-size: 38px;
                    height: 38px;
                    margin-top: -15px;
                    margin-left: 5px;
                }
            }
    
            &:hover {
                .link-text {
                    transform: translate(20px);
                    opacity: 1;
                    width: 300px;
                    font-size: 20px;
                }
            }
        }
        .video-col {
            position: relative;
            margin: 0 5px;
    
            .touchbtn {
                height: 150px;
                width: 400px;
                margin-top: -80px;
                border-color: seashell;
                background-color: seashell;
                transform: translateX(-200px);
            }
            
            .videoTrap-position {
                position: absolute;
                top: -105px;
                left: -35px;
                z-index: 3500;
                transform: scale(.5);
                opacity: 0;
                transition: all .5s ease-in-out 0.3s;
                cursor: pointer;
               
                .videoTrap {
                    width: 383px;
                    height: 353px;
                    border-radius: 10px;
                    border: 1px solid #FFF;
                    background: #171717;
                    box-shadow: 0px 4px 16px 2px rgba(0, 0, 0, 0.25);
                    .timer {
                        position: absolute;
                        top: 15px;
                        right: 15px;
                        z-index: 1000;
                        width: 62px;
                        height: 62px;
                        border-radius: 50%;
                        background: #FFFFFF;
                        padding-top: 10px;
                        img {
                            width: 25px;
                            height: 25px;
                        }
                        &.pink {
                            background: rgba(242, 150, 228, 0.95);
                        }
                        &.green {
                            background: rgba(145, 231, 225, 0.95);
                        }
                        &.purple {
                            background: rgba(167, 145, 231, 0.95);
                        }
                    }
                    .videoitem-image {
                        position: absolute;
                        width: 363px;
                        height: auto;
                        border-radius: 10px;
                        margin: 10px 10px; 
                        z-index: 1000;
                    }
                    .title{
                        display: flex;
                        height: 17px;
                        justify-content: center;
                        font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 24px;
                        letter-spacing: 0.05em;
                        color: #FFFFFF;
                        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    }
                    .video-time{
                        display: flex;
                        //width: 167px;
                        height: 24px;
                        justify-content: center;
                        font-family: 'Montserrat';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 15px;
                        line-height: 18px;
                        letter-spacing: 0.05em;
                        color: #FFFFFF;
                        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    }
                    .video-card-layout{
                        width: 100%;
                        height: 61px;
                        background: transparent;
                        border-radius: none;
                        justify-content: center;
                        display: grid;
                    }
                    .video-card-layout-kedvencek{
                        width: 100%;
                        height: 40px;
                        background: transparent;
                        border-radius: none;
                        justify-content: center;
                        display: grid;
                        button {
                            padding: 0;
                        }
                        .fa-2x {
                            font-size: 35px;
                        }
                        .video-play {
                            width: 35px;
                            height: 35px; 
                            border-radius: 50%;
                            background: #43B5AE;
                            margin-right: 10px;
                            img {
                                margin-left: 3px;
                            }
                        }
                    }
                
                }
            }
            &.first-active {
                .videoTrap-position {
                    left: 0;
                }
            }
            &.last-active {
                .videoTrap-position {
                    right: 0;
                    left: unset;
                }
            }
            &:hover {
                /*.video-item-card {
                    //transform: scale(0);
                }*/
                .videoTrap-position {
                    opacity: 0;
                    transform: scale(0);    
                }
            }
        } 
        
    }
   
}

// X-Large devices (large desktops, less than 1400px)
@media (min-width: 1200px) and (max-width: 1399.98px) { 

}




