.apply-card {
    //display: flex;
    opacity: 1;
    position: absolute;
    top: 16em;
    //transform: translate(0,-50%);
    //bottom: 50%;
    left: 2%;
    margin: 0 10px; 
    //width: 487px;
    //height: 380px;
    
    //box-shadow: 0px 6px 22px 7px rgba(106, 106, 106, 0.25);
    //border-radius: 20px;
    //padding: 25px 49px;
    z-index: 1500;
    cursor: pointer;
    .green{
        border: 1px solid rgba(145, 231, 225, 0.90); 
        background: #47999e;
       border-radius: 24px;
       padding: 12px 25px;
       margin-top: 24px;
        
    }
    &.gray{
        .price{
            box-sizing: border-box;
            width: 511px;
            height: 171px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: linear-gradient(180deg, rgba(133, 133, 133, 0.891) 0%, rgba(50, 50, 50, 0.891) 100%);
            border: 3px solid #FFFFFF;
            box-shadow: 0px 4px 12px 3px rgba(0, 0, 0, 0.12);
            border-radius: 15px;
            .price-text{
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 600;
                font-size: 48px;
                line-height: 67px;
                letter-spacing: 0.01em;
                width: 100%;
                color: #FFFFFF;

                text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            }
        }
        
    }
    span{
        &.title{
           // width: 530px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 800;
            font-size: 36px;
            line-height: 135.9%;

            letter-spacing: 0.01em;

            color: rgb(255, 255, 255, 0.9);
        }
        &.description{
            //display: flex;
            justify-content: flex-start;
            align-items: center;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 135.9%;
            width: 100%;
            letter-spacing: 0.01em;

            color: #FFFFFF;
        }
        &.description-2{
            //display: flex;
            justify-content: flex-start;
            align-items: center;
            color: #FFFFFF;
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 25px; /* 24.948px */
            letter-spacing: 0.66px;
        }
    }
    .logos {
        img {
            &.simple-image {
                width: 155px;
            }
            &.paypal-image {
                margin-left: 50px;
                width: 120px;
            }
        }
    }
    @media (min-width: 992px) and (max-width: 1199.98px) {
        width: 340px;
        height: 235px;
        top:15rem;
        padding: 25px 16px;
        &.green{
            .price{
                width: 305px;
                height: 86px;
                .price-text{
                    font-size: 32px;
                    line-height: 45px;
                }
            
            }
        }
        span{
            &.title{
                font-size: 24px;
            }
            &.description{
                font-size: 18px;
            }
        }
    }
}

.apply-card-2 {
    //display: flex;
    opacity: .9;
    position: absolute;
    top: 25em;
    //transform: translate(0,-50%);
    //bottom: 50%;
    left: 2%;
    margin: 25px 10px; 
    width: 487px;
    height: 380px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 6px 22px 7px rgba(106, 106, 106, 0.25);
    border-radius: 20px;
    padding: 25px 49px;
    z-index: 1500;
    &.green{
        border: 4px solid rgba(145, 231, 225, 0.90); 
        
    }
    span{
        &.title{
           // width: 530px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 800;
            font-size: 28px;
            line-height: 110%;

            letter-spacing: 0.01em;

            color: rgb(145, 231, 225, 0.9);
        }
      
        &.description-2{
            //display: flex;
            justify-content: flex-start;
            align-items: center;
            color: #4B4B4B;
            font-family: Montserrat;
            font-size: 18px;
            font-style: italic;
            font-weight: 700;
            line-height: 113.4%; /* 24.948px */
            letter-spacing: 0.66px;
        }
    }
    @media (min-width: 992px) and (max-width: 1199.98px) {
        width: 340px;
        height: 235px;
        top:15rem;
        padding: 25px 16px;
        span{
            &.title{
                font-size: 24px;
            }
            &.description{
                font-size: 18px;
            }
        }
    }
}

.apply-card-3 {
    //display: flex;
    opacity: .9;
    position: absolute;
    top: 50%;
    transform: translate(0,-50%);
    left: 2%;
    //margin: 25px 10px; 
    width: 45%;
    //height: 30%;
    z-index: 1500;
    cursor: pointer;
    //border: 4px solid rgba(145, 231, 225, 0.90); 
    img {
        width: 100%;
    }
    .green{
        width: 100%;
        border: 1px solid rgba(145, 231, 225, 0.90); 
        background: #47999e;
       border-radius: 12px;
       padding: 6px 6px;
        
    }
    span{
       
        &.description-2{
            justify-content: flex-start;
            align-items: center;
            color: #FFFFFF;
            font-family: Montserrat;
            font-size: 9px;
            font-style: normal;
            font-weight: 700;
            line-height: 12px; /* 24.948px */
        }
    }
}