.alakform-header {
  background: #f9f9fa;
  padding-bottom: 0;
  transition: 350ms transform;
  box-shadow: none;
  padding-top: 0 !important;
  border-bottom: 1px solid rgba(160, 158, 166, 0.3);


  @media (min-width: 1081px) {
   
  }


  @media (max-width: 1080px) {

  }

  @media (max-width: 820px) {

  }

  @media (max-width: 670px) {

  }

  @media (max-width: 645px) {
    
  }
}
.navbar-top {
  z-index: 2000;
  //height: 68px;
  background: #000;
  position: relative;
  .title-text {
    height: 68px; 
    span {
      font-size: 12px;
      @media (max-width: 1260px) {

      }
    }
  }
  .live-training-text {
    height: 68px; 
    width: 100%;
    img {
      width: 43px;
      margin-right: 8px;
    }
    span {
      color: rgb(67, 181, 174, 0.9);
      font-family: "Montserrat";
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 35px;
      letter-spacing: 2.24px;
      text-align: center;
    }
  }
}

.navbar-middle {
  position: relative;
  z-index: 1020;
  min-height: 90px; 
  background-color: rgba(137, 204, 206);
  //background: linear-gradient(90deg, rgba(137, 204, 206, 0.99) 0%, rgba(174, 213, 218, 0.99) 100%);
  box-shadow: 0px 4px 30px 10px rgba(69, 69, 69, 0.15)!important;
  .title-text {
    //position: absolute;
    height: 90px; 
    width: 100%;
    span {
      color: #FFF;
      text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      font-family: "Montserrat";
      font-size: 38px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.38px; 
    }
  }
  .button-col {
    justify-content: center;
    .belepes-button {
      height: auto;
      margin-top: -15px;
      margin-bottom: 20px;
    }
    .regisztracio-button {
      height: auto;
      margin-top: -15px;
      margin-bottom: 20px;
    }
  }
  @media (min-width: 1720px){
    .title-text {
      position: absolute;
      bottom: 0;
    }
    .button-col {
      justify-content: end;
      .belepes-button {
        height: 90px;
        margin-top: 0;
        margin-bottom: 0;
        z-index: 2000;
      }
      .regisztracio-button {
        height: 90px;
        margin-top: 0;
        margin-bottom: 0;
        z-index: 2000;
      }
    }
  }

}
.navbar {
  &.navbar-bottom {
    position: sticky;
    top: 0;
    height: 59px;
    z-index: 2000;
    background: rgba(36, 36, 36, 0.9)!important;
    li {
      list-style-type: none;
    }
    .nav-item {
      color: #FFF;
      font-family: Montserrat;
      font-size: 16px;
      @media (max-width: 1359.98px) {
        font-size: 14px;
        
      }
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.32px; 
      &:hover {
        color: #0360EC !important;
          
      }
      .nav-link {
        &:hover {
          color: rgb(145, 231, 225, 0.9) !important;
        }
        &.active {
          color: rgb(145, 231, 225, 0.9) !important;
      }
      }
      
      &.belepes {
        width: 90px;
        text-align: left;
      }
      &.videok {
        width: 110px;
        text-align: center;
      }
      &.live-edzes-nav {
        width: 170px;
        text-align: center;
      }
      &.gyik-kerdesek {
        width: 180px;
        text-align: center;
      }
      &.atalakitoprogram {
        width: 180px;
        text-align: center;
        .nav-link {
          //font-size: 18px;
          font-style: bold;
          &:hover {
            color: rgb(145, 231, 225, 0.9) !important;
          }
          &.active {
            color: rgb(145, 231, 225, 0.9) !important;
        }
        }
        .dropdown-toggle {
          white-space: unset;
          &::after {
            display: none;
          }
        }
      }
      &.hirlevel {
        width: 190px;
        text-align: center;
        .nav-link {
          //font-size: 18px;
          font-style: bold;
          &:hover {
            color: rgb(145, 231, 225, 0.9) !important;
          }
          &.active {
            color: rgb(145, 231, 225, 0.9) !important;
        }
        }
      }
      &.kapcsolat {
        width: 120px;
        text-align: center;
      }
      &.etrend {
        width: 90px;
        text-align: right;
      }
      &.kilepes {
        width: 125px;
        text-align: right;
      } 
      
    }
  }
}
.sidenav-bottom-respo {
  position: fixed;
  width: 100%;
  top: 0;
  height: auto;
  z-index: 1020;
  padding: 0;
  background: linear-gradient(90deg, rgba(145, 231, 225, 0.891) 0%, rgba(67, 181, 174, 0.891) 100%)!important;
  .live-training-text {
    height: 90px; 
    width: 100%;
    padding-top: 10px;
    background: rgba(0, 0, 0) !important;
    .first-element {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 36px;
        margin-right: 8px;
      }
      span {
        color: rgba(67, 181, 174, 0.90);
        font-family: "Montserrat";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 2.24px;  
      }
    }
    .second-element {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        color: rgb(145, 231, 225, 0.9);
        font-family: "Montserrat";
        font-size: 11px;
        font-style: normal;
        font-weight: 700;
        line-height: 14px;
        letter-spacing: 2.24px;
        text-align: center;
      }
    }
    img {
      width: 43px;
      margin-right: 8px;
    }
    span {
      color: rgba(67, 181, 174, 0.90);
      font-family: "Montserrat";
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 43px;
      letter-spacing: 2.24px;  
    }
  }
  .sidenavButton {
    background-color: transparent;
    padding: 0;
    margin: 0;
    border-radius: 50%;
  }
  .title-text span {
    margin: 10px 0;
    color: #FFF;
    text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    font-family: "Montserrat";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.38px;
    text-align: center;
  }
  img {
    width: 40px;
  }
  .rergistration-button-sidenav {
    border-radius: 25px;
    border: none;
    width: auto;
    height: auto;
    cursor: pointer;
    margin: 0px 5px;
    padding: 8px 16px;
    
    &.green{
        background: rgb(145, 231, 225, 0.9);
        box-shadow: none;
        span{
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 12px;
            
            text-align: center;
            letter-spacing: 2.5px;
            
            color: #FFFFFF;
            
            flex: none;
            order: 0;
            flex-grow: 0;
        }
    }

    &.black{
        background: #535353;
        box-shadow: none;
        span{
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 12px;
            
            text-align: center;
            letter-spacing: 2.5px;
            
            color: #FFFFFF;
            
            flex: none;
            order: 0;
            flex-grow: 0;
        }
    }

    &.text-button{
        background: transparent;
        box-shadow: none;
        span{
            color: #5A7184;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            
            flex: none;
            order: 0;
            flex-grow: 0;
        }
    }

    

    &.text-button-gyik{
        background: transparent;
        box-shadow: none;
        border-radius: 24px;
        border: none;
        width: 218px;
        height: 21px;
        cursor: pointer;
        margin: 0px 0px;
    }
    
    &.white{
        background: rgba(255, 255, 255, 0.9);
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
        
        span{
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 12px;

            text-align: center;
            letter-spacing: 2.5px;

            color: rgb(145, 231, 225, 0.9);

            flex: none;
            order: 0;
            flex-grow: 0;
        }
    } 
}
}
.sidenav {
  width: 100%;
}
.sidenav.sidenav-absolute {
  position: fixed !important;
  height: 100% !important;
  top: 0 !important;
  //width: 100% !important;
  background: linear-gradient(90deg, rgba(145, 231, 225) 0%, rgba(67, 181, 174) 100%)!important;
  box-shadow: none;
}
.sidenav-backdrop {
  visibility: hidden;
}
.sidenav-item {
  height: 60px;
  .sidenav-link {
    color: #FFF;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.32px; 
  }
}

.admin-navbar {
  background: rgba(145, 231, 225) !important;
}
