/*.dropdown-select-search {
    //padding-bottom: 70px;
    //margin-top: -30px;
    //background: #d5d6d7;
    position: absolute;
    width: 100%;
    z-index: 1100;
    top: 75px;
    .dropdown {
        width: 290px;
        height: 45px;
        //margin-top: 30px;
        padding: 0;
        
        .btn-primary {
            width: 290px;
            display: inline-flex;
            //padding: 10px 77px 11px 83px;
            justify-content: center;
            align-items: center; 
            border-radius: 30px;
            background: rgba(84, 210, 203, 0.90);
            box-shadow: 0px 8px 16px 0px rgba(31, 27, 45, 0.16);
            color: #FFF;
            font-family: "Poppins";
            font-size: 16px;
            text-transform: unset;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }
        a[aria-expanded="true"]{
            border-radius: 20px 20px 0 0;
        }
        .dropdown-toggle::after {
            position: absolute;
            right: 70px;
            top: 12px;
            content: url('../../media/icons/chevron.svg');
            border-top: none;
            border-right: none;
            border-bottom: none;
            border-left: none;
        }
    }
    .dropdown-menu {
        z-index: 1500 !important;
        inset: 0px auto auto 0px !important;
        margin-top: 0;
        padding: 30px 0;
        border-radius: 0 0 20px 20px;
        background: rgba(84, 210, 203, 0.90);
        box-shadow: 0px 8px 16px 0px rgba(31, 27, 45, 0.16); 
        width: 290px;
        transform: translate(0px, 42px) !important;
        li {
            
                height: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #FFF;
                font-family: "Poppins";
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                letter-spacing: 0.16px; 
                &:hover {
                    background: rgba(255, 255, 255, 0.90);
                    color: rgba(84, 210, 203, 0.90); 
                }
           
        }
        li:first-child  {
            border-radius: 0;
        }
        li:last-child  {
            border-radius: 0;
        }
        
    }
    .form-outline {
        //margin-top: 30px;
        margin-left: 25px;
        display: inline-flex;
        height: 45px;
        width: 290px;
        justify-content: flex-end;
        align-items: center;
        flex-shrink: 0; 
        border-radius: 30px;
        background-color: var(--white, #FFF);
        
        box-shadow: 0px 8px 16px 0px rgba(31, 27, 45, 0.16); 
        border: none;
        &:focus {
            background: none;
        }
        .form-control ~ .form-notch div {
            visibility: hidden;
            &:focus {
                visibility: hidden;
            }
        }
        .form-control ~ .form-label {
            background: url('../../media/icons/search.svg') no-repeat 65px 13px;
            width: 100%;
            color: var(--gray-600, #9691A4);
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 45px; 
            padding: 0 0 0 40px;
            display: flex;
            justify-content: center;
        }
        .form-control:focus ~ .form-label {
            transform: none;
            visibility: hidden;
        }
        .form-control.active ~ .form-label {
            transform: none;
            visibility: hidden;
        }
    }
}*/

.modal-select-search {
    //padding-bottom: 70px;
    //margin-top: -30px;
    //background: #d5d6d7;
    //position: absolute;
    //width: 100%;
    padding: 30px 0;
    
    //z-index: 1100;
    //top: 75px;
    .dropdown {
        width: 290px;
        height: 45px;
        //margin-top: 30px;
        padding: 0;
        
        .btn-primary {
            width: 290px;
            display: inline-flex;
            //padding: 10px 77px 11px 83px;
            justify-content: center;
            align-items: center; 
            border-radius: 30px;
            background: rgba(84, 210, 203, 0.90);
            box-shadow: 0px 8px 16px 0px rgba(31, 27, 45, 0.16);
            color: #FFF;
            font-family: "Poppins";
            font-size: 16px;
            text-transform: unset;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }
        a[aria-expanded="true"]{
            border-radius: 20px 20px 0 0;
        }
        .dropdown-toggle::after {
            position: absolute;
            right: 70px;
            top: 12px;
            content: url('../../media/icons/chevron.svg');
            border-top: none;
            border-right: none;
            border-bottom: none;
            border-left: none;
        }
    }
    .dropdown-menu {
        z-index: 1500 !important;
        inset: 0px auto auto 0px !important;
        margin-top: 0;
        padding: 30px 0;
        border-radius: 0 0 20px 20px;
        background: rgba(84, 210, 203, 0.90);
        box-shadow: 0px 8px 16px 0px rgba(31, 27, 45, 0.16); 
        width: 290px;
        transform: translate(0px, 42px) !important;
        li {
            
                height: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #FFF;
                font-family: "Poppins";
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                letter-spacing: 0.16px; 
                &:hover {
                    background: rgba(255, 255, 255, 0.90);
                    color: rgba(84, 210, 203, 0.90); 
                }
           
        }
        li:first-child  {
            border-radius: 0;
        }
        li:last-child  {
            border-radius: 0;
        }
        
    }
    .form-outline {
        //margin-top: 30px;
        margin-left: 25px;
        display: inline-flex;
        height: 45px;
        width: 290px;
        justify-content: flex-end;
        align-items: center;
        flex-shrink: 0; 
        border-radius: 30px;
        background-color: var(--white, #FFF);
        
        box-shadow: 0px 8px 16px 0px rgba(31, 27, 45, 0.16); 
        border: none;
        &:focus {
            background: none;
        }
        .form-control ~ .form-notch div {
            visibility: hidden;
            &:focus {
                visibility: hidden;
            }
        }
        .form-control ~ .form-label {
            background: url('../../media/icons/search.svg') no-repeat 65px 13px;
            width: 100%;
            color: var(--gray-600, #9691A4);
            font-family: "Poppins";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 45px; 
            padding: 0 0 0 40px;
            display: flex;
            justify-content: center;
        }
        .form-control:focus ~ .form-label {
            transform: none;
            visibility: hidden;
        }
        .form-control.active ~ .form-label {
            transform: none;
            visibility: hidden;
        }
    }
}