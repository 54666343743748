
.atalakitoprogram-section {
    padding-bottom: 80px;
    padding-top: 50px;
    @media (max-width: 990px) {
        padding-top: 120px;
    }
    &.live {
        @media (max-width: 990px) {
            padding-top: 210px;
        }
    }
    .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p {
            color: #ffffff;
            text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            font-family: "Montserrat";
            font-size: 36px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.45px;
            margin-bottom: 50px;
            @media (max-width: 990px) {
                font-size: 28px;
            }
        }
        
    }
    .menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        span {
            color: #ffffff;
            text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            font-family: "Montserrat";
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.45px;
            margin-bottom: 50px;
            @media (max-width: 990px) {
                font-size: 28px;
            }
        }
        img {
            width: 100%;
        }
    }
    .edzesterv {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 50px;
        span {
            color: #ffffff;
            text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            font-family: "Montserrat";
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.45px;
            margin-bottom: 50px;
            @media (max-width: 990px) {
                font-size: 28px;
            }
        }
        img {
            width: 100%;
        }
    }

}

.programlista-section {
    padding-bottom: 80px;
    padding-top: 50px;
    background-color: #ffffff;
    min-height: 25vh;
    h2 {
        width: 100%; 
        text-align: center; 
        border-bottom: 1px solid #000; 
        line-height: 0.1em;
        margin: 10px 0 20px; 
        span { 
            background:#fff; 
            padding:0 10px; 
            i {
                margin-bottom: -10px;
            }
        }
     } 
    @media (max-width: 990px) {
        padding-top: 120px;
    }
    &.live {
        @media (max-width: 990px) {
            padding-top: 210px;
        }
    }

    .events-location {
        color: #0C0C0C;
        font-family: "Montserrat";
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.45px;
        margin-bottom: 30px;
    }
    .coverimage {
        width: 100%;
    }
    .title {
        margin-bottom: 30px;
        margin-top: 30px;
        span {
            color: #0C0C0C;
            font-family: "Montserrat";
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.45px;
            
        }
    }
    .gallery-title {
        margin-bottom: 30px;
        margin-top: 30px;
        span {
            color: #0C0C0C;
            font-family: "Montserrat";
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.45px;
            
            
        }
    }
    .program-carousel {
        position: relative;
        margin-bottom: 80px;
        .carousel-container {
            display: flex;
            overflow: hidden !important;
            justify-content: left;
            max-width: 100vw;
            margin: 0;
            position: relative;
            z-index: 1000 !important;
            
            .react-multi-carousel-item {
                transform-style: unset;
                display: flex;
                align-items: center;
                padding: 0;
                .image-items {
                    width: 100%;
                    .gallery-image {
                        width: 100%;
                        //height: 100%;
                        height: 212.133px;
                        object-fit: cover;
                    }
                    .gallery-image-one {
                        width: 100%;
                        //height: 212.133px;
                        //max-height: 212.133px;
                        //object-fit: cover;
                    }
                }
               
            }
            /*
    
            .react-multiple-carousel__arrow {
                top: 0;
                //transform: translateY(69px);
                border: none;
                border-radius: 0;
                flex-grow: 0;
                background-color: rgba(0,0,0,0.7);
                z-index: 10;
                margin: 0;
                cursor: pointer;
                font-size: 5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                line-height: 0;
                transition: background-color 150ms ease-in-out;
                width: 80px;
                height: 100%;
                opacity: 0;
                &:hover {
                    opacity: 1;
                }
                @media (min-width: 1600px) and (max-width: 1919.98px) {
                    width: 70px;
                }
                @media (min-width: 1400px) and (max-width: 1599.98px) {
                    width: 60px;
                }
                @media (min-width: 1250px) and (max-width: 1399.98px) {
                    width: 55px;
                }
                @media (min-width: 1080px) and (max-width: 1249.98px) {
                    width: 50px;
                }
            }
    
            .react-multiple-carousel__arrow--right {
                
                right: 0;
                &::before {
                    background-image: url('../../media/icons/next.png');
                    background-size: 16px 35px;
                    display: inline-block;
                    height: 35px;
                    width: 16px;
                    content:"";
                    top: -10px;
                    @media (min-width: 1600px) and (max-width: 1919.98px) {
                        transform: scale(.9);
                    }
                    @media (min-width: 1400px) and (max-width: 1599.98px) {
                        transform: scale(.8);
                    }
                    @media (min-width: 1250px) and (max-width: 1399.98px) {
                        transform: scale(.7);
                    }
                    @media (min-width: 1080px) and (max-width: 1249.98px) {
                        transform: scale(.6);
                    }
                }
                
            }
    
           .react-multiple-carousel__arrow--left {
                
                left: 0;
                &::before {
                    background-image: url('../../media/icons/prev.png');
                    background-size: 16px 35px;
                    display: inline-block;
                    height: 35px;
                    width: 16px;
                    content:"";
                    top: -10px;
                    @media (min-width: 1600px) and (max-width: 1919.98px) {
                        transform: scale(.9);
                    }
                    @media (min-width: 1400px) and (max-width: 1599.98px) {
                        transform: scale(.8);
                    }
                    @media (min-width: 1250px) and (max-width: 1399.98px) {
                        transform: scale(.7);
                    }
                    @media (min-width: 1080px) and (max-width: 1249.98px) {
                        transform: scale(.6);
                    }  
                }
            }
    
            */
            
        }
        .react-multi-carousel-dot-list {
            position: absolute !important;
            display: flex !important;
            bottom: -45px !important;
            left: 50% !important;
            transform: translateX(-50%);
            right: unset!important;
            justify-content: flex-end !important;
            padding: 0 !important;
            margin: 0;
            list-style: none !important;
            text-align: center !important;
            height: 38px;
            @media (min-width: 1600px) and (max-width: 1919.98px) {
                right: 110px !important;
            }
            @media (min-width: 1300px) and (max-width: 1599.98px) {
                right: 110px !important;
            }
            @media (min-width: 1080px) and (max-width: 1299.98px) {
                right: 110px !important;
            }
            li {
                display: flex;
                align-items: center;
                height: 100%;
            }
        }
        
    }

}

