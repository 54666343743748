.trainer-section {
    margin-top: -41px;
    background-image: url('../../media/images/trainer-background.png');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 100% 100%;
    display: flex;
    align-items: flex-end;
    min-height: 714px;
    margin-bottom: 50px;
    padding-bottom: 50px;
    @media (max-width: 991.98px) {
        margin-top: 150px;
        align-items: center;
    }
    
    .trainer-image {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        img {
            height: 200px;
            width: 200px;
            border-radius: 50%;
            @media (max-width: 991.98px) {
                height: 150px;
                width: 150px;
            }
        }
        .gallery-button {
            height: 84px;
            width: 180px;
            background-color: #FFFFFF;
            border: none;
            box-shadow: none;
            span {
                color: #000000;
                font-family: Impact;
                font-size: 26px;
                letter-spacing: 0;
                line-height: 26px;
            }
            @media (max-width: 991.98px) {
                height: 60px;
                width: 140px;
                span {
                    font-size: 22px;
                    line-height: 22px;
                }
            }
        }
    }
    .trainer-name {
        width: 100%;
        margin-top: 15px;
        span {
            color: #FFFFFF;
            font-size: 100.78px;
            letter-spacing: 0;
            line-height: 110px;
            @media (max-width: 991.98px) {
                font-size: 80px;
                line-height: 85px;
            }
        }
    }
    .trainer-description {
        max-width: 672px;
        span {
            color: #FFFFFF;
            font-family: "din-condensed";
            font-size: 28px;
            letter-spacing: 0;
            line-height: 37px;
            @media (max-width: 991.98px) {
                font-size: 24px;
                line-height: 28px;
            }
        }
    }
    
}
.categorydetail-section {
    display: flex;
    align-items: flex-end;
    min-height: 300px;
    padding-bottom: 50px;
    @media (max-width: 991.98px) {
        margin-top: 190px;
        align-items: center;
        padding-bottom: 0;
        min-height: 50px;
    }

    .trainer-name {
        width: 100%;
        margin-top: 15px;
        span {
            color: #FFFFFF;
            font-size: 90px;
            letter-spacing: 0;
            line-height: 110px;
            @media (min-width: 401.98px) and (max-width: 991.98px) {
                font-size: 26px;
                line-height: 35px;
            }
            @media (max-width: 401.98px) {
                font-size: 24px;
                line-height: 30px;
            }
        }
    }
}
.more-videos-section {
    .input-div {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .form-outline {
            height: 56px;
            width: 60%;
            max-width: 390px;
            .form-control {
                height: 100%;
                padding-left: 33px;
            }
        }
        .form-outline .form-control ~ .form-label{
            position: absolute;
            top: 0;
            left: 33px;
            display: flex;
            align-items: center;
            height: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            pointer-events: none;
            transform-origin: 0 0;
            transition: all .2s ease-out;
            color: #FFFFFF !important;
            font-family: "din-condensed";
            font-size: 22px;
            font-weight: bold;
            letter-spacing: 0;
            margin-bottom: 0;
            padding: 0;
            img {
                margin-right: 5px;
                height: 22.4px;
                width: 21.38px;
            }
        }
        .form-outline .form-control:focus ~ .form-label {
            transform: translateY(-1rem) translateY(-0.35rem) scale(0.8);
        }
        .form-outline .form-control.active ~ .form-label {
            transform: translateY(-1rem) translateY(-0.35rem) scale(0.8);
        }
        .form-outline .form-control ~ .form-notch {
            display: flex;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            .form-notch-leading {
                border-radius: 28px 0 0 28px !important;
                width: 30px;
                border: 2px solid #FFFFFF;
                border-right: none;
            }
            .form-notch-middle {
                border: 2px solid #FFFFFF;
                border-right: none;
                border-left: none;
            }
            .form-notch-trailing {
                border-radius: 0 28px 28px 0 !important;
                border: 2px solid #FFFFFF;
                border-left: none;
            }
        }
        .form-outline .form-control:focus ~ .form-notch .form-notch-middle {
            border-top: none;
        }
        .form-outline .form-control.active ~ .form-notch .form-notch-middle {
            border-top: none;
        }
    }
    
}

.back-button-trainer {
    position: sticky;
    margin-left: -21vw;
    z-index: 2500;
    left: 20px;
    top: 105px;
    margin-top: -95px;
    border: none;
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    span {
        font-size: 22px;
        letter-spacing: 0;
        line-height: 26px;
        color: #FFFFFF;
    }
    img {
        height: 18px;
        //width: 34.25px;
        margin-right: 15px;
    }
    @media (min-width: 991.98px) and (max-width: 2099.98px) {
        margin-left: -2vw;
        top: 116px;
    }
    @media (max-width: 991.98px) {
        top: 85px;
    }
}