.loading-backdrop {
    background-color: transparent !important;
  }
  .loading-spinner {
    color: rgb(145, 231, 225, 0.9) !important;
  }
  
  
  
  
    *, *::after, *::before {
      box-sizing: border-box;
    }
  
    :root {
      --slider-padding: 5rem;
      --slider-index: 0;
      --slider-rest: 0;
    }
    .netflix {
      .container {
        //width: 90%;
        display: flex;
        justify-content: center;
        max-width: 100%;
        padding: 0;
        //overflow: hidden;
      }
    
      .slider {
        //--slider-index: 1;
        display: flex;
        flex-grow: 1;
        margin: .25rem;
        transform: translateX(calc(var(--slider-index) * -100% - var(--slider-rest) * 16.66%));
        
      }
    
      .slider > img {
        flex: 0 0 16.66%;
        max-width: 16.66%;
        aspect-ratio: 16 / 9;
        padding: 0 .25rem;
        border-radius: 0;
        overflow: hidden;
        transition: all .5s ease-in 0.5s;
        &:hover {
          transform: scale(1.4)
        }
      }
      
    
      .handle {
        border: none;
        border-radius: .5rem;
        flex-grow: 0;
        width: 5rem;
        background-color: rgba(0, 0, 0, .25);
        z-index: 10;
        margin: .25rem 0;
        cursor: pointer;
        font-size: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        line-height: 0;
        transition: background-color 150ms ease-in-out;
    
        &.left-handle {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
    
        &.right-handle {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    
      .text {
        transition: transform 150ms ease-in-out;
      }
    
      .handle:hover,
      .handle:focus {
        background-color: rgba(0, 0, 0, .5);
        .text {
          transform: scale(1.2);
        }
        
      }
    
      .handle:hover::after {
        color: white;
      }
    
      .container:hover .left-handle::after {
        content: \2039;
      }
    
      .container:hover .right-handle::after {
        content: \203A;
      }
    
    
    }
  
    .sidenav-form {
      .sidenav {
        width: 290px;
      }
      .navbar-icon{
        margin-right: 20px;
      }
      a {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
        padding: 1rem 1.5rem;
        height: 3rem;
        color: #000;
        .sidenav-non-slim {
          color: #000;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  
    .form-check-input[type="checkbox"]:checked {
      background-image: none;
      background-color: rgb(145, 231, 225, 0.9);
  
      &:focus {
        background-color: rgb(145, 231, 225, 0.9);
  
      }
    }
  
   
  
    .form-switch .form-check-input:checked[type="checkbox"]::after {
      background-color: rgb(145, 231, 225, 0.9);
    }
  
    @media (min-width: 576px){
      #faqmodal .modal-dialog {
        max-width: 800px!important;
        margin: 1.75rem auto;
      }
    }
  
    .select-dropdown-container {
      z-index: 3000 !important;
    }
  
    .rbc-event, .rbc-day-slot .rbc-background-event {
      background-color: rgb(145, 231, 225) !important;
    }
  
    .timepicker-head {
      background-color: rgb(145, 231, 225) !important;
    }
  
    .timepicker-icon-up, .timepicker-icon-down {
      opacity: 1;
      &:hover {
        color: #065e55!important;
      }
    }
  
    .timepicker-modal {
      --mdb-timepicker-zindex: 3000;
      z-index: var(--mdb-timepicker-zindex);
    }
  
    .carousel-indicators {
      bottom: 60px;
    }

    .modal {
        z-index: 2500 !important;
    }

    .scrollbar-container {
    height: 100%
    }

    .container {
    max-width: 1800px;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    }

    .table-format {
        border-radius: 0;
        overflow: hidden;
        .mirroring {
            -webkit-transform: scaleY(-1);
            transform: scaleY(-1);
        }
        .form-outline .form-control ~ .form-label {
          font-weight: 500;
          font-size: 17px;
          line-height: 35px;
          color: rgba(37, 38, 38, 0.5);
          left: 1.75rem;
        }
        .form-outline .form-control.active ~ .form-label {
         transform: translateY(-2rem) translateY(0.8rem) scale(0.8);
        }
        .form-outline .form-control:focus ~ .form-label {
         transform: translateY(-2rem) translateY(0.8rem) scale(0.8);
        }
        .form-outline .form-control ~ .form-notch {
          .form-notch-leading {
            border-radius: 10px 0 0 10px;
            width: 1.5rem;
            border: none;
            &:focus {
              box-shadow: none!important;
            }
          }
          .form-notch-middle {
            border: none;
            width: 79%!important;
            &:focus {
              box-shadow: none!important;
            }
          }
          .form-notch-trailing {
            border-radius: 0 10px 10px 0;
            border: none;
            &:focus {
              box-shadow: none!important;
            }
          }
        }
        .form-outline .form-control:focus ~ .form-notch {
          .form-notch-leading {
            box-shadow: none!important;
          }
          .form-notch-middle {
            box-shadow: none!important;
          }
          .form-notch-trailing {
            box-shadow: none!important;
          }
        }
        .form-outline .form-control {
          font-weight: 500;
          font-size: 20px;
          color: #000000;
        }
        .form-outline {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 22px 0;
          border-radius: 10px;
          gap: 10px;
          height: 47px;
          width:25%;
          background: #ffffff;
          backdrop-filter: blur(10px);
        }
        #filter-select {
          width: 18%;
          .form-outline {
            width:100%;
          }
        }
        #filter-select-user {
          width: 18%;
          .form-outline {
            width:100%;
          }
        }
        #filter-select-footer {
          width: 44%;
          .form-outline {
            width:100%;
          }
        }
        #filter-select-header {
          width: 20%;
          .form-outline {
            width:100%;
          }
        }
        .multiselect {
          width: 100%;
          .select-arrow {
            top: 4px;
          }
          .form-outline {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 10px;
            border-radius: 5px;
            gap: 10px;
            height: 27px;
            width:100%;
            background: #ffffff;
            backdrop-filter: blur(10px);
            .form-control {
              font-weight: normal;
              font-size: 12px;
              color: #252626;
            }
            .form-control ~ .form-label {
              left: 0.5rem;
              line-height: 13px;
              font-size: 11px;
            }
            .form-control.active ~ .form-label {
             transform: translateY(-1rem) translateY(0.2rem) scale(0.8);
            }
            .form-control:focus ~ .form-label {
             transform: translateY(-1rem) translateY(0.2rem) scale(0.8);
            }
          }
        }
        .table {
          border-radius: 0 0 0 0 !important;
          margin:0;
        }
        .table th {
          padding: .3rem 1.4rem !important;
        }
      
        .table > thead {
          background-color: #F5F5F7;
      
        }
        .table > tbody {
          background-color: #ffffff;
        }
        tfoot {
          background-color: #F5F5F7;
        }
        .select-input.focused ~ .form-notch .form-notch-leading {
          border-color: none;
          box-shadow: none;
        }
        .select-input.focused ~ .form-notch .form-notch-middle {
          border-color: none;
          box-shadow: none;
        }
        .select-input.focused ~ .form-notch .form-notch-trailing {
          border-color: none;
          box-shadow: none;
        }
        /*.select-input.focused ~ .select-arrow {
          color: #000000;
        }
        .select-input ~ .select-arrow {
          color: #000000;
        }*/
       
        .form-control-lg ~ .select-arrow {
          top: 17px;
        }
      }
      .table-format-user {
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 20px;
        box-shadow: 0 0 20px 0 rgba(0,0,0,0.3);
        .mirroring {
            -webkit-transform: scaleY(-1);
            transform: scaleY(-1);
        }
        .form-outline .form-control ~ .form-label {
          font-weight: 500;
          font-size: 17px;
          line-height: 35px;
          color: rgba(37, 38, 38, 0.5);
          left: 1.75rem;
        }
        .form-outline .form-control.active ~ .form-label {
         transform: translateY(-2rem) translateY(0.8rem) scale(0.8);
        }
        .form-outline .form-control:focus ~ .form-label {
         transform: translateY(-2rem) translateY(0.8rem) scale(0.8);
        }
        .form-outline .form-control ~ .form-notch {
          .form-notch-leading {
            border-radius: 10px 0 0 10px;
            width: 1.5rem;
            border: none;
            &:focus {
              box-shadow: none!important;
            }
          }
          .form-notch-middle {
            border: none;
            width: 79%!important;
            &:focus {
              box-shadow: none!important;
            }
          }
          .form-notch-trailing {
            border-radius: 0 10px 10px 0;
            border: none;
            &:focus {
              box-shadow: none!important;
            }
          }
        }
        .form-outline .form-control:focus ~ .form-notch {
          .form-notch-leading {
            box-shadow: none!important;
          }
          .form-notch-middle {
            box-shadow: none!important;
          }
          .form-notch-trailing {
            box-shadow: none!important;
          }
        }
        .form-outline .form-control {
          font-weight: 500;
          font-size: 20px;
          color: #000000;
        }
        .form-outline {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 22px 21px;
          border-radius: 10px;
          gap: 10px;
          height: 47px;
          width:28%;
          background: #ffffff;
          backdrop-filter: blur(10px);
        }
        #filter-select {
          width: 18%;
          .form-outline {
            width:100%;
          }
        }
        #filter-select-user {
          width: 18%;
          .form-outline {
            width:100%;
          }
        }
        #filter-select-footer {
          width: 44%;
          .form-outline {
            width:100%;
          }
        }
        .multiselect {
          width: 100%;
          .select-arrow {
            top: 4px;
          }
          .form-outline {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 10px;
            border-radius: 5px;
            gap: 10px;
            height: 27px;
            width:100%;
            background: #ffffff;
            backdrop-filter: blur(10px);
            .form-control {
              font-weight: normal;
              font-size: 12px;
              color: #252626;
            }
            .form-control ~ .form-label {
              left: 0.5rem;
              line-height: 13px;
              font-size: 11px;
            }
            .form-control.active ~ .form-label {
             transform: translateY(-1rem) translateY(0.2rem) scale(0.8);
            }
            .form-control:focus ~ .form-label {
             transform: translateY(-1rem) translateY(0.2rem) scale(0.8);
            }
          }
        }
        .table {
          border-radius: 0 0 0 0 !important;
          margin:0;
        }
        .table th {
          padding: .3rem 1.4rem !important;
        }
      
        .table > thead {
          background-color: #F5F5F7;
      
        }
        .table > tbody {
          background-color: #ffffff;
        }
        tfoot {
          background-color: #F5F5F7;
        }
        .select-input.focused ~ .form-notch .form-notch-leading {
          border-color: none;
          box-shadow: none;
        }
        .select-input.focused ~ .form-notch .form-notch-middle {
          border-color: none;
          box-shadow: none;
        }
        .select-input.focused ~ .form-notch .form-notch-trailing {
          border-color: none;
          box-shadow: none;
        }
        /*.select-input.focused ~ .select-arrow {
          color: #000000;
        }
        .select-input ~ .select-arrow {
          color: #000000;
        }*/
        
        .form-control-lg ~ .select-arrow {
          top: 17px;
        }
      }
      .table-format-blacklist {
        max-width: 800px;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 20px;
        box-shadow: 0 0 20px 0 rgba(0,0,0,0.3);
        .mirroring {
            -webkit-transform: scaleY(-1);
            transform: scaleY(-1);
        }
        .form-outline .form-control ~ .form-label {
          font-weight: 500;
          font-size: 17px;
          line-height: 35px;
          color: rgba(37, 38, 38, 0.5);
          left: 1.75rem;
        }
        .form-outline .form-control.active ~ .form-label {
         transform: translateY(-2rem) translateY(0.8rem) scale(0.8);
        }
        .form-outline .form-control:focus ~ .form-label {
         transform: translateY(-2rem) translateY(0.8rem) scale(0.8);
        }
        .form-outline .form-control ~ .form-notch {
          .form-notch-leading {
            border-radius: 10px 0 0 10px;
            width: 1.5rem;
            border: none;
            &:focus {
              box-shadow: none!important;
            }
          }
          .form-notch-middle {
            border: none;
            width: 79%!important;
            &:focus {
              box-shadow: none!important;
            }
          }
          .form-notch-trailing {
            border-radius: 0 10px 10px 0;
            border: none;
            &:focus {
              box-shadow: none!important;
            }
          }
        }
        .form-outline .form-control:focus ~ .form-notch {
          .form-notch-leading {
            box-shadow: none!important;
          }
          .form-notch-middle {
            box-shadow: none!important;
          }
          .form-notch-trailing {
            box-shadow: none!important;
          }
        }
        .form-outline .form-control {
          font-weight: 500;
          font-size: 20px;
          color: #000000;
        }
        .form-outline {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 22px 21px;
          border-radius: 10px;
          gap: 10px;
          height: 47px;
          width:28%;
          background: #ffffff;
          backdrop-filter: blur(10px);
        }
        #filter-select {
          width: 18%;
          .form-outline {
            width:100%;
          }
        }
        #filter-select-user {
          width: 18%;
          .form-outline {
            width:100%;
          }
        }
        #filter-select-footer {
          width: 44%;
          .form-outline {
            width:100%;
          }
        }
      
        .table {
          border-radius: 0 0 0 0 !important;
          margin:0;
          text-align: left;
        }
        .table th {
          padding: .3rem 1.4rem !important;
        }
      
        .table > thead {
          background-color: #F5F5F7;
      
        }
        .table > tbody {
          background-color: #ffffff;
        }
        tfoot {
          background-color: #F5F5F7;
        }
        .select-input.focused ~ .form-notch .form-notch-leading {
          border-color: none;
          box-shadow: none;
        }
        .select-input.focused ~ .form-notch .form-notch-middle {
          border-color: none;
          box-shadow: none;
        }
        .select-input.focused ~ .form-notch .form-notch-trailing {
          border-color: none;
          box-shadow: none;
        }
        /*.select-input.focused ~ .select-arrow {
          color: #000000;
        }
        .select-input ~ .select-arrow {
          color: #000000;
        }*/
        
        .form-control-lg ~ .select-arrow {
          top: 17px;
        }
      }
      
      @media (min-width: 576px) and (max-width: 1599.98px){
        .table-format-user .form-outline .form-control {
          font-size: 16px;
        }
        .table-format .multiselect .form-outline .form-control ~ .form-label {
          font-size: 6px;
        }
      }
      .accordion-item {
        .collapse.show{
          height: auto!important;
        }
      }

.elofizetes-col {
  height: 60px;
  border-bottom: 1px solid #000000;
  span {
    color: #464646;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }
}

.marker-image {
  width: 35px;
  height: 75px;
}

.cookie-button-classes {
  .accept {
    display: inline-flex;
    padding: 15px 0px 15px 0px;
    justify-content: flex-end;
    height: 51px!important; 
    align-items: center;
    flex-shrink: 0; 
    border-radius: 5px!important;
    background: rgba(67, 181, 174, 0.90) !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important; 
    color: rgba(255, 255, 255, 0.90)!important;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 111.111% */
    letter-spacing: 2.5px;
    @media (max-width: 420px){
      height: 41px; 
      font-size: 14px;
      line-height: 16px;
    }

  }
  .reject {
    display: inline-flex;
    padding: 15px 0px 15px 0px;
    justify-content: flex-end;
    height: 51px!important; 
    align-items: center;
    flex-shrink: 0; 
    border-radius: 5px!important;
    background: #DBDBDB !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)!important; 
    color: rgba(255, 255, 255, 0.90)!important;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 111.111% */
    letter-spacing: 2.5px;
    @media (max-width: 420px){
      height: 41px; 
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.cookie-content {
  color: #242424;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  .help-text {
    color: rgba(67, 181, 174, 0.90);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
  }
  .reject {
    display: inline-flex;
    padding: 15px 10px 15px 10px;
    justify-content: flex-end;
    height: 51px; 
    align-items: center;
    flex-shrink: 0; 
    border-radius: 5px!important;
    background: #DBDBDB !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)!important; 
    color: rgba(255, 255, 255, 0.90)!important;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 111.111% */
    letter-spacing: 2.5px;
    border: none;
    @media (max-width: 420px){
      height: 41px; 
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.profile-form {
  .modal-title-form {
    color: #464646;
    font-size: 24px;
    font-style: normal;
    font-weight: bold;
    line-height: 150%; /* 36px */ 
  }
  .profile-form {
    .modal-title-form {
      color: #464646;
      font-family: "Poppins";
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 36px */ 
    }
    input {
      width: 100%;
      
      @media (min-width: 767.98px) {
        max-width: 535px;
          
      }
      height: 45px;
      border-radius: 8px;
      border: 1px solid #C3CAD9;
      background: #FFF; 
      outline: none;
      padding: 18px 17px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 23px;
      color: rgba(37, 38, 38);
    
      
    
      & + input {
        margin-top: 10px;
      }
    
      &.error {
        border-color: #c86e6e;
      }
    
      &[readonly],
      &.disabled {
        color: rgba(37, 38, 38, 0.35);
      }
    
     
      
    
      &:-webkit-autofill,
      &:-webkit-autofill:focus,
      &:-internal-autofill-selected {
        border-color: #efe2de !important;
        background-color: #efe2de !important;
        -webkit-box-shadow: 0 0 0 50px #efe2de inset;
      }
    }
  
    
    label {
      margin-bottom: 5px;
      color: #5A7184;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal; 
    }
  
    .input-group {
      @media (min-width: 767.98px) {
        max-width: 535px;
          
      }
      input {
        border-right: none;
      }
      
      .input-group-text {
        height: 45px;
        border-left: none;
        border-radius: 8px;
      }
    }
    .apply-card-modal {
      margin: 0; 
      width: 434px;
      height: 242px;
      background: rgba(255, 255, 255, 0.9);
      box-shadow: none;
      border-radius: 20px;
      padding: 20px 33px;
      padding: 0px 10px 20px 10px;
      @media (max-width: 767.98px) {
        width: 100%;
      }
      &.green{
          border: 1px solid rgba(145, 231, 225, 0.90); 
          @media (max-width: 767.98px) {
            .price{
              width: 100%;
              height: 85px;
              .price-text{
                  font-size: 40px;
                  line-height: 67px;
              }
          }
          }
          .price{
              box-sizing: border-box;
              width: 100%;
              height: 85px;
              //display: flex;
              justify-content: center;
              align-items: center;
              background: linear-gradient(180deg, rgba(145, 231, 225, 0.891) 0%, rgba(67, 181, 174, 0.891) 100%);
              border: none;
              box-shadow: none;
              border-radius: 15px;
              @media (max-width: 767.98px) {
                width: 100%;
                  
              }
              .price-text{
                  margin-top: -20px;
                  font-family: 'Montserrat';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 40px;
                  //line-height: 67px;
                  letter-spacing: 0.01em;
  
                  color: #FFFFFF;
  
                  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              }
          }
          
      }
      span{
          &.title{
             // width: 530px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 800;
              font-size: 28px;
              line-height: 135.9%;
  
              letter-spacing: 0.01em;
  
              color: rgb(145, 231, 225, 0.9);
              @media (max-width: 767.98px) {
                font-size: 24px;
                  
              }
          }
          &.description{
              display: flex;
              justify-content: flex-start;
              align-items: center;
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 500;
              font-size: 20px;
              //line-height: 135.9%;
  
              letter-spacing: 0.01em;
  
              color: #ffffff;
          }
      }
    }
    .kuponkod-div {
      width: 261px;
    }
    .rergistration-div {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%,-0);
      .rergistration-button {
        border-radius: .25rem;
        //border: none;
        width: 351px;
        height: 67px;
        cursor: pointer;
        margin: 5px 10px;
        &.green{
            background: rgb(145, 231, 225, 0.9);
            box-shadow: none;
            span{
              color: #FFF;
              text-align: center;
              font-family: Poppins;
              font-size: 15px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px; /* 133.333% */
              letter-spacing: 2.5px; 
            }
        }
    
        &.black{
            background: #535353;;
            box-shadow: none;
            span{
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
                
                text-align: center;
                letter-spacing: 2.5px;
                
                color: #FFFFFF;
                
                flex: none;
                order: 0;
                flex-grow: 0;
            }
        }
        
        &.white{
            background: rgba(255, 255, 255, 0.9);
            box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
            
            span{
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
    
                text-align: center;
                letter-spacing: 2.5px;
    
                color: rgb(145, 231, 225, 0.9);
    
                flex: none;
                order: 0;
                flex-grow: 0;
            }
        } 
    }
    }
  }
  
  input {
      width: 100%;
      display: flex;
      padding: 11px 10px;
      align-items: center;
      gap: 10px;
      //border: none;
      border-radius: .25rem;
      align-self: stretch; 
      background: #FFF; 
      outline: none;
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 23px;
      color: rgba(37, 38, 38);
      @media (min-width: 767.98px) {
          max-width: unset;
      }
    & + input {
      margin-top: 10px;
    }
  
    &.error {
      border-color: #c86e6e;
    }
  
    &[readonly],
    &.disabled {
      color: rgba(37, 38, 38, 0.35);
    }
  
    &:-webkit-autofill,
    &:-webkit-autofill:focus,
    &:-internal-autofill-selected {
      border-color: #efe2de !important;
      background-color: #efe2de !important;
      -webkit-box-shadow: 0 0 0 50px #efe2de inset;
    }
  }

  
  label {
      margin-bottom: 5px;
      color: #2D2D2D;
      font-size: 18px;
      font-style: normal;
      font-weight: normal;
      line-height: 26px;
      span {
          color: #E20000;
          font-size: 18px;
          font-style: normal;
          font-weight: normal;
          line-height: normal;
      }
  }

  textarea {
      width: 100%;
      border: none;
      background: #FFF; 
      outline: none;
      padding: 11px 10px;
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 23px;
      color: rgba(37, 38, 38);
  }
  
}

.single-image-uploader {
  .images-area {
    width: 100%;
    height: 180px;
    position: relative;
    border: none;
    border-radius: 0;
    padding: 30px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #ffffff;

    &.center {
      justify-content: center;
    }

    .uploaded-item {
      width: 145px;
      height: 145px;
      margin-left: 1%;
      margin-right: 25px;
      position: relative;
      // display: inline-block;

      .image-icons {
        position: absolute;
        top: 8px;
        left: 8px;
        display: flex;

        .image-icon {
          width: 30px;
          height: 30px;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          cursor: pointer;

          & + .image-icon {
            margin-left: 8px;
          }

          i {
            font-size: 20px;
            cursor: "pointer";

            &.fa-trash-alt {
              color: #dc4c64;
            }
          }
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        //border-radius: 50%;
      }
    }

    label.upload-button {
      // width: 193px;
      padding-left: 15px;
      padding-right: 15px;
      height: 67px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid rgba(141, 139, 148, 0.4);
      border-radius: 15px;
      cursor: pointer;
      font-style: normal;
      font-weight: normal;
      font-size: 17px;
      line-height: 23px;
      text-align: center;
      color: #252626;

      input[type="file"] {
        position: absolute;
        width: 1px;
        height: 1px;
        opacity: 0;
        left: -999px;
        right: -999px;
      }

      i {
        font-weight: normal;
        font-size: 17px;
        line-height: 17px;
        text-align: center;
        color: #252626;
        margin-right: 10px;
      }
    }

    &.highlight {
      border: 2px dashed black;
    }

    @media (max-width: 570px) {
      flex-direction: column;
      height: auto;

      .uploaded-item {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }

  .helper-text {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: rgba(37, 38, 38, 0.4);
    margin-top: 10px;

    i {
      margin-right: 10px;
    }
  }
}

.image-uploader {
  .images-area {
    width: 100%;
    @media (max-width: 1300px) {
      width: 80%;
    }
    @media (max-width: 1080px) {
      width: 100%;
    }
    min-height: 205px;
    position: relative;
    border: 1px dashed rgba(37, 38, 38, 0.4);
    border-radius: 10px;
    padding: 30px;
    display: flex;
    align-items: center;
      justify-content: center;
    .uploaded-images {
      display: flex;
      flex-wrap: wrap;
      max-width: 90%;
      .uploaded-item {
        width: 30%;
        margin-left: 1%;
        margin-right: 1%;
        height: 145px;
        position: relative;
        box-shadow: 0 0 1px #00000059;
        // display: inline-block;

        .image-icons {
          position: absolute;
          top: 8px;
          left: 8px;
          display: flex;

          .image-icon {
            width: 30px;
            height: 30px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            background: white;

            & + .image-icon {
              margin-left: 8px;
            }

            &.hero-indicator {
              background: #252626;
              display: none;

              i {
                font-size: 17px;
                line-height: 17px;
                text-align: center;
                color: #f9f9fa;
              }
            }

            &.delete {
              color: red;
              cursor: pointer;

              i {
                color: red;
              }
            }
          }
        }

        img {
          min-width: 80px;
          width: 100%;
          height: 100%;
          object-fit: cover;
          cursor: pointer;
        }

        &:first-child {
          .image-icons {
            .image-icon {
              &.hero-indicator {
                display: flex;
              }
            }
          }
        }
      }
      .uploaded-item-2 {
        width: 100%;
        margin-left: 1%;
        margin-right: 1%;
        height: 145px;
        position: relative;
        box-shadow: 0 0 1px #00000059;
        // display: inline-block;

        .image-icons {
          position: absolute;
          top: 8px;
          left: 8px;
          display: flex;

          .image-icon {
            width: 30px;
            height: 30px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            background: white;

            & + .image-icon {
              margin-left: 8px;
            }

            &.hero-indicator {
              background: #252626;
              display: none;

              i {
                font-size: 17px;
                line-height: 17px;
                text-align: center;
                color: #f9f9fa;
              }
            }

            &.delete {
              color: red;
              cursor: pointer;

              i {
                color: red;
              }
            }
          }
        }

        img {
          min-width: 80px;
          width: 100%;
          height: 100%;
          object-fit: cover;
          cursor: pointer;
        }

        &:first-child {
          .image-icons {
            .image-icon {
              &.hero-indicator {
                display: flex;
              }
            }
          }
        }
      }
    }

    label.upload-button {
      width: 67px;
      height: 67px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid rgba(141, 139, 148, 0.4);
      border-radius: 15px;
      cursor: pointer;
      //position: absolute;
      //top: 67px;
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 23px;
      text-align: center;
      color: #252626;

      input[type="file"] {
        position: absolute;
        width: 1px;
        height: 1px;
        opacity: 0;
        left: -999px;
        right: -999px;
      }

      .full-content {
        display: none;
      }

      &.uploaded-images-0 {
        width: auto;
        min-width: 202px;
        padding-left: 60px;
        padding-right: 60px;
        right: 50%;
        margin-right: -101px;
        // background: white;

        .full-content {
          display: block;

          i {
            margin-right: 5px;
          }
        }

        .content {
          display: none;
        }
      }

      @for $i from 1 through 4 {
        &.uploaded-images-#{$i} {
          left: ($i * 20% + 2%);
        }
      }

      &.uploaded-images-5 {
        display: none;
      }
    }

    &.highlight {
      border: 2px dashed black;
    }

    @media (max-width: 1180px) {
      height: 185px;

      .uploaded-images {
        .uploaded-item {
          height: 125px;
        }
      }
    }

    @media (max-width: 940px) {
      height: auto;
      display: flex;
      flex-direction: column;
      row-gap: 16px;

      .uploaded-images {
        flex-direction: column;
        row-gap: 10px;

        .uploaded-item {
          width: 100%;
          height: 145px;
        }
      }

      label.upload-button {
        position: static;
        width: 100%;

        &.uploaded-images-0 {
          width: unset;
          min-width: unset;
          margin-right: unset;
        }
      }
    }
  }

  &:not(.multiple) {
    .images-area {
      .uploaded-images {
        justify-content: center;
      }

      label.upload-button {
        &.uploaded-images-1 {
          display: none;
        }
      }
    }
  }

  .helper-text {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: rgba(37, 38, 38, 0.4);
    margin-top: 10px;

    i {
      margin-right: 10px;
    }
  }
}
