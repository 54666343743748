footer.alakreform-footer {
  width: 100%;
  height: 484px; 
  @media (min-width: 576px) and (max-width: 767.98px) {
    height:690px;
  } 
  @media (max-width: 575.98px) {
    height:750px;
  }
  background: #1E1E1E;
  overflow: hidden;
  //position: fixed;
  bottom:0;
  .footer-col-1 {
    display: flex;
    @media (min-width: 992px) and (max-width: 1199.98px) {

    }
    @media (min-width: 768px) and (max-width: 991.98px) {
      
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
      justify-content: center !important;
      margin: 15px 0;
    }
    @media (max-width: 575.98px) {
      justify-content: center !important;
      margin: 15px 0;
    }
  }
  .footer-col-2 {
    @media (min-width: 992px) and (max-width: 1199.98px) {

    }
    @media (min-width: 768px) and (max-width: 991.98px) {
      
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
      
    }
    @media (max-width: 575.98px) {
      
    }
    
  }
  .footer-col-3 {
    @media (min-width: 992px) and (max-width: 1199.98px) {

    }
    @media (min-width: 768px) and (max-width: 991.98px) {
      
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
      
    }
    @media (max-width: 575.98px) {
      
    }
    
  }
  .footer-col-4 {
    @media (min-width: 992px) and (max-width: 1199.98px) {

    }
    @media (min-width: 768px) and (max-width: 991.98px) {
      
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
      
    }
    @media (max-width: 575.98px) {
      margin: 20px 0;
    }
    
  }
  
  .footer-top {
    height:396px;
    @media (min-width: 576px) and (max-width: 767.98px) {
      height:600px;
      padding: 0 72px;
    }
    @media (max-width: 575.98px) {
      height:655px;
      padding: 0 0 0 25px;
    }
    padding: 0 72px;
    display: flex;
    align-items: center;

    img {
      width: 85%;
      height: auto;
      max-width: 800px;
    }
    .coulom_1 {
      width: 100%;
      height: 158px;
      display: contents; 
      .profil-title {
        width: 43px;
        height: 24px;
        margin: 0 0 15px 0;
  
        //font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
  
        /* identical to box height, or 24px */
        display: flex;
        align-items: center;
  
        /* white */
        color: #FFFFFF;
      }
      .profil-text {
        width: 135px;
        height: 21px;
  
        //font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
  
        /* identical to box height, or 24px */
        display: flex;
        align-items: center;
  
        /* white */
        color: #FFFFFF;
        opacity: 0.6;
        
        flex: none;
        order: 1;
        flex-grow: 0;
        &:hover {
          opacity: 1;
        }
      }
    }
    .coulom_2 {
      width: 100%;
      height: 158px;
      display: contents; 
      .help-title {
        width: 130px;
        height: 24px;
        margin: 0 0 15px 0;
  
        //font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
  
        /* identical to box height, or 24px */
        display: flex;
        align-items: center;
  
        /* white */
        color: #FFFFFF;
      }
      .help-text {
        //width: 160px;
        height: 21px;
  
        //font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
  
        /* identical to box height, or 24px */
        display: flex;
        align-items: center;
  
        /* white */
        color: #FFFFFF;
        opacity: 0.6;
        
        flex: none;
        order: 1;
        flex-grow: 0;
        &:hover {
          opacity: 1;
        }
      }
    }
    .coulom_3 {
      width: 100%;
      height: 158px;
      @media (max-width: 767.98px) {
        height: 135px;
      }
      flex-shrink: 0; 
      position: relative;
      .iratkozzfel-title {
        position: absolute;
        top: 0;
        left: 20px;
        // width: 330px;
         height: 31px;
   
         //font-family: 'Noto Sans';
         font-style: normal;
         font-weight: 700;
         font-size: 20px;
         line-height: 130%;
   
         /* identical to box height, or 24px */
         display: flex;
         align-items: center;
         text-align: center;
   
         /* white */
         color: #FFFFFF;
         @media (max-width: 767.98px) {
          font-size: 18px;
          line-height: 100%;
          height: 25px;
          left: 0;
        }
       }
       .iratkozzfel-text {
        position: absolute;
        top: 45%;
        -webkit-transform: translateX(-0) translateY(-50%);
        transform: translateX(0) translateY(-50%); 
        left:20px;
        width: 185px;
        height: 21px;
        @media (max-width: 767.98px) {
          font-size: 12px;
          line-height: 100%;
          height: 18px;
          left: 0;
        }
  
        //font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
  
        /* identical to box height, or 24px */
        display: flex;
        align-items: center;
  
        /* white */
        color: #FFFFFF;
        opacity: 0.6;
        
        flex: none;
        order: 1;
        flex-grow: 0;
      }
      .email-form {
        position: absolute;
        bottom: 0;
        .email-img {
          position: absolute;
          top: 18px;
          width: 18px;
          height: 18px;
          left: 20px;
        }
        .email-button {
          box-shadow: none;
          position: absolute;
          top: 8px;
          right: 8px;
          display: flex;
          padding: 8px 20px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px; 
          border-radius: 40px;
          background: rgba(145, 231, 225, 0.90);
          color: var(--white, #FFF);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px; 
          text-transform: initial;
          border: none;
          &:hover {
            background: rgba(251, 251, 251, 0.90);
            color: rgba(145, 231, 225, 0.90);
          }
        }
        .form-outline{
          display: flex;
          max-width: 416px;
          height: 52px;
          padding: 8px 8px 8px 16px;
          justify-content: flex-start;
          align-items: center;
          gap: 180px;
          flex-shrink: 0; 
          border-radius: 40px;
          border: 1px solid rgba(255, 255, 255, 0.12);
          background: rgba(255, 255, 255, 0.04);
          @media (max-width: 575.98px) {
            width: 100%;
            padding: 8px 8px 8px 8px;
          }
      
          .form-control {
            height: 36px;
            width: 58%;
            padding-top: 8px;
            padding-bottom: 8px;
            padding-left: .75rem;
            padding-right: .75rem;
            border: 0;
            background: transparent;
            transition: all .2s linear;
            margin-left: 17px;
          }
      
          .form-control ~ .form-label {
            position: absolute;
            top: 0;
            max-width: 90%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            left: 45px;
            pointer-events: none;
            transform-origin: 0 0;
            transition: all .2s ease-out;
            padding: 0;
            color: var(--white, #FFF);
            opacity: 0.6;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 52px; 
          }
          .form-control.active ~ .form-label {
            //display: none;
            visibility: hidden;
          }
          .form-control:focus ~ .form-label {
            //display: none;
            visibility: hidden;
          }
      
          .form-control ~ .form-notch {
            //display: none;
            visibility: hidden;
          }
      
        }
      }
    }
    
  }
  .copyright {
    height: 88px;
    padding: 0 100px;
    @media (min-width: 768px) and (max-width: 991.98px) {
      padding: 0 65px; 
    }
    @media (min-width: 576px) and (max-width: 767.98px) {
      padding: 0 55px; 
    }
    @media (max-width: 575.98px) {
      padding: 0 20px; 
    }
    opacity: 0.7;
    border-top: 1px solid #FFFFFF;
    display: flex;
    align-items: center;
    
    span {
      //font-family: 'Noto Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;

      color: rgb(145, 231, 225, 0.9);

      opacity: 0.7;
    }
  } 
    
}


// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
}

// Small devices (landscape phones, less than 768px)
@media (min-width: 576px) and (max-width: 767.98px) {
}

// Medium devices (tablets, less than 992px)
@media (min-width: 768px) and (max-width: 991.98px) {
}

// Large devices (desktops, less than 1200px)
@media (min-width: 992px) and (max-width: 1199.98px) {
  footer.alakreform-footer {
    height: 340px;  
    
    .footer-top {
      height:270px;
      padding: 0 40px;
  
      .coulom_3 {
        .iratkozzfel-title {
          font-size: 17px;
         }
        
        .email-form {
          .email-button {
            padding: 8px 8px;
            font-size: 12px;
          }
          .form-outline{
            width: 100%;
          }
        }
      }
      
    }
    .copyright {
      height: 70px;
      padding: 0 50px;
    } 
      
  }
}

// X-Large devices (large desktops, less than 1400px)
@media (min-width: 1200px) and (max-width: 1399.98px) { 
  footer.alakreform-footer {
    height: 340px;  
    
    .footer-top {
      height:270px;
      padding: 0 40px;
  
      .coulom_3 {
        .iratkozzfel-title {
          font-size: 17px;
         }
        
        .email-form {
          .email-button {
            padding: 8px 8px;
            font-size: 12px;
          }
          .form-outline{
            width: 100%;
          }
        }
      }
      
    }
    .copyright {
      height: 70px;
      padding: 0 50px;
    } 
      
  }
 }