
.signup-modal {
  .modal-header {
    padding: 10px 50px;
    height: 75px;
    //background-color: rgb(145, 231, 225, 0.9) !important;
    //box-shadow: 0px 4px 30px 10px rgba(69, 69, 69, 0.15) !important;
    border: none;
  }
  .modal-dialog {
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
  }
  .modal-content {
    border-radius: 20px;
    border: 3px solid rgb(145, 231, 225, 0.9);
  }
  .modal-body {
    padding: 0px 80px 50px 80px;
    @media (max-width: 767.98px) {
      padding: 0px 10px 20px 10px;
    }
  }
  .modal-title {
   
    width: 100%;
    color: #767676;
    text-align: center;
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 52px */ 
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .modal-text {
   
    width: 100%;
    color: #767676;
    text-align: center;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 52px */ 
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
}

.coupon-modal {
  .modal-header {
    padding: 10px 50px;
    height: 75px;
    //background-color: rgb(145, 231, 225, 0.9) !important;
    //box-shadow: 0px 4px 30px 10px rgba(69, 69, 69, 0.15) !important;
    border: none;
  }
  .modal-dialog {
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
  }
  .modal-content {
    border-radius: 20px;
    border: 3px solid rgb(145, 231, 225, 0.9);
  }
  .modal-body {
    padding: 0px 80px 50px 80px;
    @media (max-width: 767.98px) {
      padding: 0px 10px 20px 10px;
    }
  }
  .modal-title {
   
    width: 100%;
    color: #767676;
    text-align: center;
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 52px */ 
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .modal-text {
   
    width: 100%;
    color: #767676;
    text-align: center;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 52px */ 
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
}

.category-modal {
  
  .modal-header {
    padding: 10px 30px;
    height: 75px;
    border: none;
    //background-color: rgb(145, 231, 225, 0.9) !important;
    //box-shadow: 0px 4px 30px 10px rgba(69, 69, 69, 0.15) !important;
  }
  .modal-content {
    background: linear-gradient(to bottom, rgba(0, 0, 0) 5%, rgb(0, 0, 0) 12%);
    border: 1px solid #ffffff;
  }
  .modal-dialog {
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
  }
  .modal-content {
    border-radius: 20px;
    border: 3px solid rgb(145, 231, 225, 0.9);
  }
  .modal-body {
    padding: 120px 30px;
  }
  .modal-close {
    background: transparent;
    padding: 0;
    margin: 0;
  }
  .modal-title {
   
    width: 100%;
    color: #767676;
    text-align: center;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 52px */ 
    display: flex;
    justify-content: center;
  }
}

.category-modal {
  
  .modal-header {
    padding: 40px 30px 0 0;
    height: 35px;
    //background-color: rgb(145, 231, 225, 0.9) !important;
    //box-shadow: 0px 4px 30px 10px rgba(69, 69, 69, 0.15) !important;
  }
  .modal-content {
    background: linear-gradient(to bottom, rgba(0, 0, 0) 5%, rgb(0, 0, 0) 12%);
    border: 1px solid #ffffff;
  }
  .modal-dialog {
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
  }
  .modal-body {
    padding: 30px 30px;
  }
  .modal-close {
    background: transparent;
    padding: 0;
    margin: 0;
  }
  .modal-title {
   
    width: 100%;
    color: #767676;
    text-align: center;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 52px */ 
    display: flex;
    justify-content: center;
  }
  .videotar-modal {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 12%);
    padding-bottom: 80px;
    position: relative;
    //margin-top: -95px;

    &.isLogedIn-false {
        background: lightgray 50% !important;
    }
    
    .motivation-video {
        height: 610px;
        width: 100%;
        background: rgba(36, 36, 36, 0.90); 
        position: relative;
        margin-top: 157px;
        padding-bottom: 80px;
        .motivacio-background{
            position: absolute;
            right: 0px;
            top: 50%;
            -webkit-transform: translateX(0%) translateY(-50%);
            transform: translateX(0%) translateY(-50%); 
            width:799px;
            height: 428px;
        }
        .motivatio-title {
            padding-top: 63px;
            span {
                color: #FFF;
                text-align: center;
                font-family: 'Montserrat';
                font-size: 30px;
                font-style: normal;
                font-weight: 400;
                line-height: 45px;
                letter-spacing: 1.5px; 
            }
        }
        .video-content {
            border-radius: 10px !important;
            width: 653px;
            height: 374px; 
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 500;
    
        }
        
    }
    .video-item-card {
        position: relative;
        z-index: 1000;
        width: 100%;
        //max-width: 286px;
        height: 164px;
        border-radius: 10px;
        margin: 15px 0;
        transform: scale(1);
        transition: all .5s ease-in-out 0.5s;
        cursor: pointer;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background-position: center top !important;
        overflow: hidden;
        .timer {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 1050;
          width: 62px;
          height: 62px;
          //border-radius: 50%;
          background: url("../../media/icons/Black And White.png") no-repeat;
          background-size: 100% 100%;
          //background-color: transparent;
          //background-color: rgba(211, 211, 211);
          box-shadow: none;
          padding-top: 10px;
          img {
              position: absolute;
              top: 3px;
              left: 50%;
              transform: translateX(-23%) translateY(0);
              width: 28px;
              height: 28px;
          }
          span {
              position: absolute;
              top: 49%;
              left: 50%;
              transform: translateX(-5%) translateY(0);
              flex-shrink: 0;
              color: rgba(255, 255, 255, 0.9);
              font-family: "Montserrat";
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              //line-height: normal; 
          }
         /* &.pink {
              span {
                  color: rgba(242, 150, 228);
              }
          }
          &.green {
              span {
                  color: rgba(145, 231, 225);
              }
          }
          &.purple {
              span {
                  color: rgba(167, 145, 231);
              }
          }*/
      }
        .title{
            padding-left: 10px;
            color: rgba(255, 255, 255, 0.90);
            text-shadow: 8px 4px 7px rgba(67, 67, 67, 0.60); 
            display: flex;
            width: 81%;
            justify-content: flex-start;
            font-family: 'Montserrat';
            font-size: 12px;
            font-style: italic;
            font-weight: 700;
            line-height: 123%;
            letter-spacing: 1px; 
          &.pink {
              color: rgba(242, 150, 228, 0.95);
          }
          &.green {
              color: rgba(145, 231, 225, 0.95);
          }
          &.purple {
              color: rgba(167, 145, 231, 0.95);
          }
      }
      .video-time{
          padding-left: 10px;
          display: flex;
          width: 167px;
          height: 30px;
          justify-content: flex-start;
          color: #FFF;
          font-family: 'Montserrat';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px; /* 56.105px */ 
      }
      .video-card-layout{
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 35%;
        background: rgba(0, 0, 0, 0.40);
        backdrop-filter: blur(75px); 
        border-radius: 0 0 10px 10px;
        .alakreform-icon {
            position: absolute;
            top: 12px;
            right: 7px;
            width: 50px;
            height: auto;
        }
        .text-position {
            position: absolute;
            top: 50%;
            transform: translateX(0%) translateY(-50%); 
            left: 0;
            max-width: 100%
        }
    }
        
    }
    .videotar-title {
        width: 100%;
        padding-top: 70px;
        padding-bottom: 50px;
        span {
            color: #232323;
            text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            font-family: Montserrat;
            font-size: 45px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.45px; 
            &.visible-false {
                opacity: 0;
            }
        }
    }
    .category-title {
        color: #e5e5e5;
        font-family: "Montserrat";
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.45px;
        padding-left: 25px;
        background-color: transparent;
        border: none;
       
        .link-text {
            color: #54b9c5;
            font-family: "Montserrat";
            font-size: 20px;
            opacity: 0;
            transition: all 0.6s ease-in-out;
            display: inline-flex;
            margin-left: 10px;
            &::after {
                content: "\203A";
                font-size: 38px;
                height: 38px;
                margin-top: -15px;
                margin-left: 5px;
            }
        }

        &:hover {
            .link-text {
                transform: translate(20px);
                opacity: 1;
                width: 300px;
                font-size: 20px;
            }
        }
    }
    .video-col {
        position: relative;
        margin: 0 5px;

        .touchbtn {
            height: 150px;
            width: 400px;
            margin-top: -80px;
            border-color: seashell;
            background-color: seashell;
            transform: translateX(-200px);
        }
        
        .videoTrap-position {
            position: absolute;
            top: -105px;
            left: -35px;
            z-index: 3500;
            transform: scale(.5);
            opacity: 0;
            transition: all .5s ease-in-out 0.5s;
            cursor: pointer;
           
            .videoTrap {
                width: 383px;
                height: 353px;
                border-radius: 10px;
                border: 1px solid #FFF;
                background: #171717;
                box-shadow: 0px 4px 16px 2px rgba(0, 0, 0, 0.25);
                .timer {
                    position: absolute;
                    top: 15px;
                    right: 15px;
                    z-index: 1000;
                    width: 62px;
                    height: 62px;
                    border-radius: 50%;
                    background: #FFFFFF;
                    padding-top: 10px;
                    img {
                        width: 25px;
                        height: 25px;
                    }
                }
                .videoitem-image {
                  //position: absolute;
                  width: 100%;
                  height: auto;
                  border-radius: 10px;
                  //margin: 10px 10px; 
                  z-index: 1000;
                }
                .title{
                    display: flex;
                    height: 17px;
                    justify-content: center;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    letter-spacing: 0.05em;
                    color: #FFFFFF;
                    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                }
                .video-time{
                    display: flex;
                    //width: 167px;
                    height: 24px;
                    justify-content: center;
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 18px;
                    letter-spacing: 0.05em;
                    color: #FFFFFF;
                    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                }
                .video-card-layout{
                    width: 100%;
                    height: 61px;
                    background: transparent;
                    border-radius: none;
                    justify-content: center;
                    display: grid;
                }
                .video-card-layout-kedvencek{
                    width: 100%;
                    height: 40px;
                    background: transparent;
                    border-radius: none;
                    justify-content: center;
                    display: grid;
                    button {
                        padding: 0;
                    }
                    .fa-2x {
                        font-size: 35px;
                    }
                    .video-play {
                        width: 35px;
                        height: 35px; 
                        border-radius: 50%;
                        background: #43B5AE;
                        margin-right: 10px;
                        img {
                            margin-left: 3px;
                        }
                    }
                }
            
            }
        }
        &.first-active {
            .videoTrap-position {
                left: 0;
            }
        }
        &.last-active {
            .videoTrap-position {
                right: 0;
                left: unset;
            }
        }
        @media all and (min-width: 1199.98px) {
            &:hover {
                /*.video-item-card {
                    //transform: scale(0);
                }*/
                .videoTrap-position {
                    opacity: 1;
                    transform: scale(1);    
                }
            }
        }
        
    } 
    
}
}

.signin-modal {
  .modal-header {
    padding: 10px 50px;
    height: 75px;
    //background-color: rgb(145, 231, 225, 0.9) !important;
    //box-shadow: 0px 4px 30px 10px rgba(69, 69, 69, 0.15) !important;
    border: none;
  }
  .modal-dialog {
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
  }
  .modal-content {
    border-radius: 20px;
    border: 3px solid rgb(145, 231, 225, 0.9);
  }
  .modal-body {
    padding: 0px 80px 50px 80px;
    @media (max-width: 767.98px) {
      padding: 0px 10px 20px 10px;
    }
  }
  .modal-title {
   
    width: 100%;
    color: #767676;
    text-align: center;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 52px */ 
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
}

.profile-form {
  .modal-title-form {
    color: #464646;
    font-family: "Poppins";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */ 
  }
  input {
    width: 100%;
    
    @media (min-width: 767.98px) {
      max-width: 535px;
        
    }
    height: 45px;
    border-radius: 8px;
    border: 1px solid #C3CAD9;
    background: #FFF; 
    outline: none;
    padding: 18px 17px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 23px;
    color: rgba(37, 38, 38);
  
    
  
    & + input {
      margin-top: 10px;
    }
  
    &.error {
      border-color: #c86e6e;
    }
  
    &[readonly],
    &.disabled {
      color: rgba(37, 38, 38, 0.35);
    }
  
   
    
  
    &:-webkit-autofill,
    &:-webkit-autofill:focus,
    &:-internal-autofill-selected {
      border-color: #efe2de !important;
      background-color: #efe2de !important;
      -webkit-box-shadow: 0 0 0 50px #efe2de inset;
    }
  }

  
  label {
    margin-bottom: 5px;
    color: #5A7184;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
  }

  .input-group {
    @media (min-width: 767.98px) {
      max-width: 535px;
        
    }
    input {
      border-right: none;
    }
    
    .input-group-text {
      height: 45px;
      border-left: none;
      border-radius: 8px;
    }
  }
  .apply-card-modal {
    margin: 0; 
    width: 434px;
    height: 242px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: none;
    border-radius: 20px;
    padding: 20px 33px;
    padding: 0px 10px 20px 10px;
    @media (max-width: 767.98px) {
      width: 100%;
    }
    &.green{
        border: 1px solid rgba(145, 231, 225, 0.90); 
        @media (max-width: 767.98px) {
          .price{
            width: 100%;
            height: 85px;
            .price-text{
                font-size: 40px;
                line-height: 67px;
            }
        }
        }
        .price{
            box-sizing: border-box;
            width: 100%;
            height: 85px;
            //display: flex;
            justify-content: center;
            align-items: center;
            background: linear-gradient(180deg, rgba(145, 231, 225, 0.891) 0%, rgba(67, 181, 174, 0.891) 100%);
            border: none;
            box-shadow: none;
            border-radius: 15px;
            @media (max-width: 767.98px) {
              width: 100%;
                
            }
            .price-text{
                margin-top: -20px;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 600;
                font-size: 40px;
                //line-height: 67px;
                letter-spacing: 0.01em;

                color: #FFFFFF;

                text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            }
        }
        
    }
    span{
        &.title{
           // width: 530px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 800;
            font-size: 28px;
            line-height: 135.9%;

            letter-spacing: 0.01em;

            color: rgb(145, 231, 225, 0.9);
            @media (max-width: 767.98px) {
              font-size: 24px;
                
            }
        }
        &.description{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            //line-height: 135.9%;

            letter-spacing: 0.01em;

            color: #ffffff;
        }
    }
  }
  .kuponkod-div {
    width: 261px;
  }
  .rergistration-div {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%,-0);
    .rergistration-button {
      border-radius: 30px;
      border: none;
      width: 351px;
      height: 67px;
      cursor: pointer;
      margin: 5px 10px;
      &.green{
          background: rgb(145, 231, 225, 0.9);
          box-shadow: none;
          span{
            color: #FFF;
            text-align: center;
            font-family: Poppins;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px; /* 133.333% */
            letter-spacing: 2.5px; 
          }
      }
  
      &.black{
          background: #535353;;
          box-shadow: none;
          span{
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 20px;
              
              text-align: center;
              letter-spacing: 2.5px;
              
              color: #FFFFFF;
              
              flex: none;
              order: 0;
              flex-grow: 0;
          }
      }
      
      &.white{
          background: rgba(255, 255, 255, 0.9);
          box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
          
          span{
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 20px;
  
              text-align: center;
              letter-spacing: 2.5px;
  
              color: rgb(145, 231, 225, 0.9);
  
              flex: none;
              order: 0;
              flex-grow: 0;
          }
      } 
  }
  }
}

.form-check-input::before {
  position: absolute;
  box-shadow: none;
  border-radius: 50%;
  width: 10px !important;
  height: 18px !important;
  background-color: transparent;
  opacity: 0;
  pointer-events: none;
  transform: scale(0);
  top: 6px;
  left: 15px;
}
.form-check-input[type="checkbox"]:checked::after {
  display: block;
  transform: rotate(45deg);
  border-width: .125rem;
    border-top-width: 0.125rem;
    border-left-width: 0.125rem;
  border-color: #fff;
    border-top-color: rgb(255, 255, 255);
    border-left-color: rgb(255, 255, 255);
  width: 5px !important;
  height: 10px !important;
  border-style: solid;
    border-top-style: solid;
    border-left-style: solid;
  border-top: 0;
  border-left: 0;
  margin-left: 0;
  margin-top: 0;
  background-color: transparent;
  top: 3px;
  left: 7px;
}
.form-check-input:focus::before {
  opacity: 0;
  box-shadow: none;
}
.form-check-input[type="checkbox"]:focus::after {
  background-color: transparent;
}
.checkbox-div {
  
  input {
    width: 20px;
    height: 20px;
    border-radius: 50% !important;
    border: 1px solid #C3CAD9;
    background: #FFF; 
    outline: none;
    padding: 0;
    color: rgba(37, 38, 38);
  
    
  
    & + input {
      margin-top: 0;
    }
  
    &.error {
      border-color: #c86e6e;
    }
  
    &[readonly],
    &.disabled {
      color: rgba(37, 38, 38, 0.35);
    }
  
   
    
  
    &:-webkit-autofill,
    &:-webkit-autofill:focus,
    &:-internal-autofill-selected {
      border-color: #efe2de !important;
      background-color: #efe2de !important;
      -webkit-box-shadow: 0 0 0 50px #efe2de inset;
    }
  }
  
  label {
    margin-bottom: 5px;
    color: #5A7184;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
  }
 
}

.signin-form {
  .modal-title-form {
    color: #464646;
    font-family: "Poppins";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */ 
  }
  input {
    width: 100%;
    //max-width: 535px;
    height: 56px;
    border-radius: 8px;
    border: 1px solid #C3CAD9;
    background: #FFF; 
    outline: none;
    padding: 18px 17px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 23px;
    color: rgba(37, 38, 38);
  
    
  
    & + input {
      margin-top: 10px;
    }
  
    &.error {
      border-color: #c86e6e;
    }
  
    &[readonly],
    &.disabled {
      color: rgba(37, 38, 38, 0.35);
    }
  
   
    
  
    &:-webkit-autofill,
    &:-webkit-autofill:focus,
    &:-internal-autofill-selected {
      border-color: #efe2de !important;
      background-color: #efe2de !important;
      -webkit-box-shadow: 0 0 0 50px #efe2de inset;
    }
  }
  
  label {
    margin-bottom: 5px;
    color: #5A7184;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
  }

  .input-group {
    //max-width: 535px;
    input {
      border-right: none;
    }
    
    .input-group-text {
      height: 56px;
      border-left: none;
      border-radius: 8px;
    }
  }

}

.onTouch-modal{
  .modal-dialog {
    max-width: 500px;
  }
  .modal-content {
    background: #171717 !important;
    border-radius: 10px;
    border: 1px solid #FFF;
    //padding-bottom: 25px;
  }
  .modal-body {
    padding: 10px 23px;
    .btn-close {
      box-sizing: content-box;
      background: transparent;
      border: 0;
      color: #ffffff;
      opacity: 1;
      height: auto;
      width: auto;
      padding: 5px;
      margin: 2px;
      //position: absolute;
    }
    .videoTrap {
      width: 100%;
      //height: 353px;
      position: relative;
          .timer {
              position: absolute;
              top: 15px;
              right: 15px;
              z-index: 1500;
              width: 62px;
              height: 62px;
              border-radius: 50%;
              background: #FFFFFF;
              padding-top: 10px;
              img {
                  width: 25px;
                  height: 25px;
              }
              &.pink {
                background: rgba(242, 150, 228, 0.95);
              }
              &.green {
                background: rgba(145, 231, 225, 0.95);
              }
              &.purple {
                background: rgba(167, 145, 231, 0.95);
              }
          }
          .videoitem-image {
            //position: absolute;
            width: 100%;
            height: auto;
            border-radius: 10px;
            //margin: 10px 10px; 
            z-index: 1000;
          }
          .videoitem-promo {
            position: absolute;
            border-radius: 10px;
            //margin: 10px 10px; 
            z-index: 1020;
            opacity: 0;
            transform: scale(0);
            transition: all .4s ease-in-out 0.4s;
            &.show {
                transform: scale(1);
                opacity: 1;
                transition: all .4s ease-in-out 0.4s;
            }
        }
          .title{
              display: flex;
              //height: 17px;
              justify-content: center;
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 500;
              font-size: 20px;
              line-height: 24px;
              letter-spacing: 0.05em;
              color: #FFFFFF;
              text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          }
          .video-time{
              display: flex;
              //width: 167px;
              height: 24px;
              justify-content: center;
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 18px;
              letter-spacing: 0.05em;
              color: #FFFFFF;
              text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          }
          .video-card-layout{
              //position: absolute;
              //top: 315px;
              width: 100%;
              height: 61px;
              background: transparent;
              border-radius: none;
              justify-content: center;
              display: grid;
          }
          .video-card-layout-kedvencek{
              //bottom: 45px;
              //position: absolute;
              width: 100%;
              height: 40px;
              background: transparent;
              border-radius: none;
              justify-content: center;
              display: grid;
              margin: 20px 0 0 0;
              button {
                  padding: 0;
              }
              .fa-2x {
                  font-size: 35px;
              }
              .video-play {
                  width: 35px;
                  height: 35px; 
                  border-radius: 50%;
                  background: #43B5AE;
                  margin-right: 10px;
                  img {
                      margin-left: 3px;
                  }
              }
          }
      
      }
  }
  .videoTrap-position {
    position: absolute;
    top: -105px;
    left: -35px;
    z-index: 3500;
    transform: scale(.5);
    opacity: 0;
    transition: all .5s ease-in-out 0.5s;
    cursor: pointer;
   
    .videoTrap {
        width: 383px;
        height: 353px;
        border-radius: 10px;
        border: 1px solid #FFF;
        background: #171717;
        box-shadow: 0px 4px 16px 2px rgba(0, 0, 0, 0.25);
        .timer {
            position: absolute;
            top: 15px;
            right: 15px;
            z-index: 1000;
            width: 62px;
            height: 62px;
            border-radius: 50%;
            background: #FFFFFF;
            padding-top: 10px;
            img {
                width: 25px;
                height: 25px;
            }
        }
        .videoitem-image {
          //position: absolute;
            width: 100%;
            height: auto;
            border-radius: 10px;
            //margin: 10px 10px; 
            z-index: 1000;
        }
        .title{
            display: flex;
            height: 17px;
            justify-content: center;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.05em;
            color: #FFFFFF;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
        .video-time{
            display: flex;
            //width: 167px;
            height: 24px;
            justify-content: center;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            letter-spacing: 0.05em;
            color: #FFFFFF;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
        .video-card-layout{
            width: 100%;
            height: 61px;
            background: transparent;
            border-radius: none;
            justify-content: center;
            display: grid;
        }
        .video-card-layout-kedvencek{
            width: 100%;
            height: 40px;
            background: transparent;
            border-radius: none;
            justify-content: center;
            display: grid;
            button {
                padding: 0;
            }
            .fa-2x {
                font-size: 35px;
            }
            .video-play {
                width: 35px;
                height: 35px; 
                border-radius: 50%;
                background: #43B5AE;
                margin-right: 10px;
                img {
                    margin-left: 3px;
                }
            }
        }
    
    }
}
}

.altalanos-modal {
  .modal-header {
    padding: 10px 50px;
    height: 75px;
    //background-color: rgb(145, 231, 225, 0.9) !important;
    //box-shadow: 0px 4px 30px 10px rgba(69, 69, 69, 0.15) !important;
    border: none;
  }
  .modal-dialog {
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
  }
  .modal-content {
    border-radius: 20px;
    border: 3px solid rgb(145, 231, 225, 0.9);
  }
  .modal-body {
    padding: 0px 50px 50px 50px;
    @media (max-width: 767.98px) {
      padding: 0px 10px 20px 10px;
    }
  }
  .modal-title {
    width: 100%;
    color: #767676;
    text-align: center;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 52px */ 
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
}

.feliratkozas-modal {
  .modal-header {
    padding: 10px 10px 10px 50px;
    //height: 75px;
    //background-color: rgb(145, 231, 225, 0.9) !important;
    //box-shadow: 0px 4px 30px 10px rgba(69, 69, 69, 0.15) !important;
    border: none;
  }
  .modal-dialog {
    margin-right: auto;
    margin-left: auto;
  }
  .modal-content {
    border-radius: 0;
    border: none;
  }
  .modal-body {
    padding: 10px 20px 20px 50px;
    @media (max-width: 767.98px) {
      padding: 0px 10px 20px 10px;
    }
  }
  .modal-title {
    width: 100%;
    color: #767676;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 52px */ 
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .hirelvel-col {
    background: url("../../media/images/Kezdokep.png") no-repeat;
    background-size: 100% 100%;
  }
  .hozzajarulas {
    display: flex;
    span {
      width: 100%;
      color: #767676;
      text-align: left;
      font-family: Poppins;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 13px;
      justify-content: center;
    }
  }
}

.nyeremeny-modal {
  .modal-header {
    padding: 10px 50px;
    height: 75px;
    //background-color: rgb(145, 231, 225, 0.9) !important;
    //box-shadow: 0px 4px 30px 10px rgba(69, 69, 69, 0.15) !important;
    border: none;
  }
  .modal-dialog {
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
  }
  .modal-content {
    border-radius: 20px;
    border: 3px solid rgb(145, 231, 225, 0.9);
    backdrop-filter: blur(75px);
  background: rgba(251, 251, 251, 0.70);
  }
  .modal-body {
    padding: 0px 50px 50px 50px;
    @media (max-width: 767.98px) {
      padding: 0px 10px 20px 10px;
    }
  }
  .modal-title {
   
    width: 100%;
    color: #767676;
    text-align: center;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 52px */ 
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
}

.cookies-modal {
  .modal-header {
    padding: 10px 50px;
    height: 75px;
    //background-color: rgb(145, 231, 225, 0.9) !important;
    //box-shadow: 0px 4px 30px 10px rgba(69, 69, 69, 0.15) !important;
    border: none;
  }
  .modal-dialog {
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
  }
  .modal-content {
    border-radius: 20px;
    border: 3px solid rgb(145, 231, 225, 0.9);
  }
  .modal-body {
    padding: 0px 50px 50px 50px;
    @media (max-width: 767.98px) {
      padding: 0px 10px 20px 10px;
    }
  }
  .modal-title {
    width: 100%;
    color: #767676;
    text-align: center;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 52px */ 
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .modal-description {
    color: #767676;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
  }
  .cookie-detail {
    
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 400px;
      padding: 15px 0;
      span {
        color: #767676;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 36px */ 
      }
      .form-switch .form-check-input {
        background-image: none;
        border-width: 0;
        border-radius: .4375rem;
        width: 2rem;
        height: .875rem;
        background-color: rgba(0,0,0,.25);
        margin-top: .3em;
        margin-right: 8px;
        &:checked{
          background-color: rgb(145, 231, 225, 0.9);
        }
      }
      .form-switch .form-check-input::after {
        content: "";
        position: absolute;
        border: none;
        z-index: 2;
        border-radius: 50%;
        width: 1.25rem;
        height: 1.25rem;
        background-color: rgba(0,0,0,.5);
        margin-top: -0.1875rem;
        box-shadow: 0 0px 3px 0 rgba(0,0,0,.07),0 2px 2px 0 rgba(0,0,0,.04);
        transition: background-color .2s,transform .2s;
      }
      .form-check-input[type="checkbox"]:checked::after {
        background: rgb(145, 231, 225, 0.9);
        width: 1.25rem !important;
        height: 1.25rem !important;
        top: 0px;
        left: 0px;
      }
    }
    .detail {
      span {
        color: #767676;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 
      }
    }
  }
}
