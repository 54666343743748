/*
.carousel-container {
    display: flex;
    overflow: unset !important;
    justify-content: left;
    max-width: 100vw;
    margin: 0 28px;
    position: relative;
    z-index: 1000 !important;
    
    .react-multi-carousel-item {
        transform-style: unset;
        &.active {
                margin-right: 8px;
        }
    }

    .react-multiple-carousel__arrow {
        border: none;
        border-radius: .5rem;
        flex-grow: 0;
        width: 28px;
        background-color: rgba(86, 86, 86, .25);
        z-index: 10;
        margin: .25rem 0;
        cursor: pointer;
        font-size: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        line-height: 0;
        transition: background-color 150ms ease-in-out;
        min-width: 1rem !important;
        min-height: 43px;
        height: 185px;
        &:hover {
            background-color: rgba(86, 86, 86, .95);
        }
    }

    .react-multiple-carousel__arrow--right {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        right: -28px;
        &::before {
            content: "\203A";
            font-size: 50px;
            top: -10px;
        }
    }

    .react-multiple-carousel__arrow--left {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        left: -28px;
        opacity: 1;
        &::before {
            content: "\2039";
            font-size: 50px;
            top: -10px;
        }
    }

    &.firststep {
        .react-multiple-carousel__arrow--left {
            opacity: 0;
            &::before {
                content: "\2039";
                font-size: 50px;
                top: -10px;
            }
        }
    }

    
}
.react-multi-carousel-dot-list {
    position: absolute !important;
    bottom: unset !important;
    display: flex !important;
    top: -16px;
    left: unset !important;
    right: 35px !important;
    justify-content: flex-end !important;
    
    padding: 0 !important;
    margin: 0 0 0 50px;
    list-style: none !important;
    text-align: center !important;
  }
.react-multi-carousel-dot button {
    //position: absolute;
    display: inline-block!important;
    width: 30px!important;
    height: 3px!important;
    border-radius: 0!important;
    opacity: 1!important;
    padding: 1px 1px 1px 1px!important;
    box-shadow: none!important;
    transition: background .5s!important;
    border-width: .6px!important;
    border-style: solid;
    border-color: grey!important;
    padding: 0!important;
    margin: 0 2px 0 0 !important;
    outline: 0;
    background: #080808;
    
    cursor: pointer;
  }
.react-multi-carousel-dot--active button {
background: #ffffff !important;
}

 .carousel-container-slide {
    display: flex;
    overflow: unset !important;
    justify-content: left;
    max-width: 100vw;
    margin: 0;
    position: relative;
    z-index: 1000 !important;
    
    .react-multi-carousel-item {
        transform-style: unset;
        &.active {
                margin-right: 8px;
        }
    }

    .react-multiple-carousel__arrow {
        border: none;
        border-radius: .5rem;
        flex-grow: 0;
        width: 28px;
        background-color: transparent;
        z-index: 10;
        margin: .25rem 0;
        cursor: pointer;
        font-size: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        line-height: 0;
        transition: background-color 150ms ease-in-out;
        min-width: 1rem !important;
        &:hover {
            background-color: transparent;
        }
    }

    .react-multiple-carousel__arrow--right {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        right: 15px;
        &::before {
            content: "\203A";
            font-size: 50px;
            top: -10px;
        }
    }

    .react-multiple-carousel__arrow--left {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        left: 15px;
        &::before {
            content: "\2039";
            font-size: 50px;
            top: -10px;
        }
    }
}

*/

.top-carousel {
    position: relative;
    .carousel-container {
        display: flex;
        overflow: hidden !important;
        justify-content: left;
        max-width: 100vw;
        margin: 0;
        position: relative;
        z-index: 1000 !important;
        &.firststep {
            margin-left: 0;
            
        }
        .react-multi-carousel-item {
            transform-style: unset;
            //width: 519px !important;
            //height: 550px;
            display: flex;
            align-items: center;
            justify-content: center;
            //margin-left: 5px;
           
        }

        .react-multiple-carousel__arrow {
            top: 50%;
            transform: translateY(-165.55px);
            border: none;
            border-radius: 0;
            flex-grow: 0;
            width: 149px;
            background-color: transparent;
            z-index: 10;
            margin: 0 0 0 0;
            cursor: pointer;
            font-size: 5rem;
            display: none;
            align-items: center;
            justify-content: center;
            color: white;
            line-height: 0;
            transition: background-color 150ms ease-in-out;
            //height: 463.1px;
            opacity: 0;
            &:hover {
                opacity: 1;
            }
        }

        .react-multiple-carousel__arrow--right {
            
            right: -65px;
            &::before {
                content: "\203A";
                font-size: 50px;
                top: -10px;
            }
        }

        .react-multiple-carousel__arrow--left {
            
            left: -65px;
            &::before {
                content: "\2039";
                font-size: 50px;
                top: -10px;
            }
        }

        &.firststep {
            .react-multiple-carousel__arrow--left {
                opacity: 0;
                &::before {
                    content: "\2039";
                    font-size: 50px;
                    top: -10px;
                }
            }
        }

        
    }
    .react-multi-carousel-dot-list {
        position: absolute !important;
        bottom: unset !important;
        display: flex !important;
        top: -38px;
        left: unset !important;
        right: 0 !important;
        justify-content: flex-end !important;
        padding: 0 !important;
        margin: 0;
        list-style: none !important;
        text-align: center !important;
        height: 38px;
        li {
            display: flex;
            align-items: center;
            height: 100%;
        }
    }
    .react-multi-carousel-dot button {
        //position: absolute;
        display: inline-block!important;
        width: 74px!important;
        height: 7px!important;
        border-radius: 0!important;
        opacity: 0.43 !important;
        background-color: #FFFFFF;
        padding: 1px 1px 1px 1px!important;
        box-shadow: none!important;
        transition: background .5s!important;
        border: none !important;
        padding: 0!important;
        margin: 0 2px 0 0 !important;
        outline: 0;
        cursor: pointer;
        @media (min-width: 1600px) and (max-width: 1919.98px) {
            width: 60px!important;
            height: 5px!important;
        }
        @media (min-width: 1300px) and (max-width: 1599.98px) {
            width: 55px!important;
            height: 5px!important;
        }
        @media (min-width: 1080px) and (max-width: 1299.98px) {
            width: 50px!important;
            height: 5px!important;
        }
    }
    .react-multi-carousel-dot--active button {
    opacity: 1 !important;
    background: #FFFFFF !important;
    }
}
.more-carousel {
    .carousel-container {
        display: flex;
        overflow: unset !important;
        justify-content: left;
        max-width: 100vw;
        margin: 0;
        position: relative;
        z-index: 1000 !important;
        
        .react-multi-carousel-item {
            transform-style: unset;
            //height: 600px;
            display: flex;
            align-items: center;
            padding: 0 5px;
           
        }

        .react-multiple-carousel__arrow {
            top: 0;
            //transform: translateY(69px);
            border: none;
            border-radius: 0;
            flex-grow: 0;
            background-color: rgba(0,0,0,0.7);
            z-index: 10;
            margin: 0;
            cursor: pointer;
            font-size: 5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            line-height: 0;
            transition: background-color 150ms ease-in-out;
            width: 80px;
            height: 100%;
            opacity: 0;
            &:hover {
                opacity: 1;
            }
            @media (min-width: 1600px) and (max-width: 1919.98px) {
                width: 70px;
            }
            @media (min-width: 1400px) and (max-width: 1599.98px) {
                width: 60px;
            }
            @media (min-width: 1250px) and (max-width: 1399.98px) {
                width: 55px;
            }
            @media (min-width: 1080px) and (max-width: 1249.98px) {
                width: 50px;
            }
        }

        .react-multiple-carousel__arrow--right {
            
            right: 0;
            &::before {
                background-image: url('../../media/icons/next.png');
                background-size: 16px 35px;
                display: inline-block;
                height: 35px;
                width: 16px;
                content:"";
                top: -10px;
                @media (min-width: 1600px) and (max-width: 1919.98px) {
                    transform: scale(.9);
                }
                @media (min-width: 1400px) and (max-width: 1599.98px) {
                    transform: scale(.8);
                }
                @media (min-width: 1250px) and (max-width: 1399.98px) {
                    transform: scale(.7);
                }
                @media (min-width: 1080px) and (max-width: 1249.98px) {
                    transform: scale(.6);
                }
            }
            
        }

        .react-multiple-carousel__arrow--left {
            
            left: 0;
            &::before {
                background-image: url('../../media/icons/prev.png');
                background-size: 16px 35px;
                display: inline-block;
                height: 35px;
                width: 16px;
                content:"";
                top: -10px;
                @media (min-width: 1600px) and (max-width: 1919.98px) {
                    transform: scale(.9);
                }
                @media (min-width: 1400px) and (max-width: 1599.98px) {
                    transform: scale(.8);
                }
                @media (min-width: 1250px) and (max-width: 1399.98px) {
                    transform: scale(.7);
                }
                @media (min-width: 1080px) and (max-width: 1249.98px) {
                    transform: scale(.6);
                }  
            }
        }

        &.firststep {
            .react-multiple-carousel__arrow--left {
                opacity: 0;
                display:none;
                &::before {
                    content: "\2039";
                    font-size: 50px;
                    top: -10px;
                }
            }
        }

        
    }
    .react-multi-carousel-dot-list {
        position: absolute !important;
        bottom: unset !important;
        display: flex !important;
        top: 0;
        left: unset !important;
        right: 0 !important;
        justify-content: flex-end !important;
        padding: 0 !important;
        margin: 0;
        list-style: none !important;
        text-align: center !important;
        height: 38px;
        @media (min-width: 1600px) and (max-width: 1919.98px) {
            right: 110px !important;
        }
        @media (min-width: 1300px) and (max-width: 1599.98px) {
            right: 110px !important;
        }
        @media (min-width: 1080px) and (max-width: 1299.98px) {
            right: 110px !important;
        }
        li {
            display: flex;
            align-items: center;
            height: 100%;
        }
    }
    .react-multi-carousel-dot button {
        //position: absolute;
        display: inline-block!important;
        width: 55px!important;
        height: 5px!important;
        border-radius: 0!important;
        opacity: 0.43 !important;
        background-color: #FFFFFF;
        padding: 1px 1px 1px 1px!important;
        box-shadow: none!important;
        transition: background .5s!important;
        border: none !important;
        padding: 0!important;
        margin: 0 2px 0 0 !important;
        outline: 0;
        cursor: pointer;
        @media (min-width: 1600px) and (max-width: 1919.98px) {
            width: 60px!important;
            height: 5px!important;
        }
        @media (min-width: 1300px) and (max-width: 1599.98px) {
            width: 55px!important;
            height: 5px!important;
        }
        @media (min-width: 1080px) and (max-width: 1299.98px) {
            width: 50px!important;
            height: 5px!important;
        }
    }
    .react-multi-carousel-dot--active button {
        opacity: 1 !important;
        background: #FFFFFF !important;
    }
}

.button-container {
    position: absolute;
    //width: 100% !important;
    left: 0;
    top: 40%;
    transform: translateY(-50%);
    padding: 0 25px;
    //left: -210px;
    .custom-button-group {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .left-button {
            border: none;
            box-shadow: none;
            background-color: transparent;
            padding: 40px 40px;
            opacity: 0.5;
            &:hover {
                opacity: 1;
            }
            img {
                width: 34.25px;
                height: 69.7px;
            }
            &.firststep {
                opacity: 0;
                &:hover {
                    opacity: 0;
                    cursor:unset;
                }
                
            }
        }
        .right-button {
            border: none;
            box-shadow: nonet;
            background-color: transparent;
            padding: 40px 40px;
            opacity: 0.5;
            transition: all .3s ease-in 0.2s;
            &:hover {
                opacity: 1;
                transition: all .3s ease-in 0.2s;
            }
            img {
                width: 34.25px;
                height: 69.7px;
            }

        }
    }
    @media (min-width: 1080px) and (max-width: 1599.98px) {
        padding: 0 10px;
        left: -210px;
        .custom-button-group {
            .left-button {
                padding: 20px 20px;
                img {
                    transform: scale(0.8);
                }
            }
            .right-button {
                padding: 20px 20px;
                img {
                    transform: scale(0.8);
                }
    
            }
        }
    }
}

.button-container-trainer {
    position: absolute;
    width: 1920px !important;
    left: -181.5px;
    top: 40%;
    transform: translateY(-50%);
    padding: 0 25px;
    .custom-button-group {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .left-button {
            border: none;
            box-shadow: none;
            background-color: transparent;
            padding: 40px 40px;
            opacity: 0.5;
            &:hover {
                opacity: 1;
            }
            img {
                width: 34.25px;
                height: 69.7px;
            }
            &.firststep {
                opacity: 0;
                &:hover {
                    opacity: 0;
                    cursor:unset;
                }
                
            }
        }
        .right-button {
            border: none;
            box-shadow: nonet;
            background-color: transparent;
            padding: 40px 40px;
            opacity: 0.5;
            transition: all .3s ease-in 0.2s;
            &:hover {
                opacity: 1;
                transition: all .3s ease-in 0.2s;
            }
            img {
                width: 34.25px;
                height: 69.7px;
            }

        }
    }
    @media (min-width: 1080px) and (max-width: 1599.98px) {
        padding: 0 10px;
        left: -110px;
        .custom-button-group {
            .left-button {
                padding: 20px 20px;
                img {
                    transform: scale(0.8);
                }
            }
            .right-button {
                padding: 20px 20px;
                img {
                    transform: scale(0.8);
                }
    
            }
        }
    }
}

.trainer-carousel {
    position: relative;
    .carousel-container {
        display: flex;
        overflow: hidden !important;
        justify-content: left;
        max-width: 100vw;
        margin: 0;
        position: relative;
        z-index: 1000 !important;
        
        .react-multi-carousel-item {
            transform-style: unset;
            //width: 504px !important;
            //height: 450px;
            display: flex;
            align-items: flex-start;
            padding: 0 9px;
            margin-top: 0;
           
            @media (min-width: 1080px) and (max-width: 1299.98px) {
                height: 420px;
            }
        }

        .react-multiple-carousel__arrow {
            top: 50%;
            transform: translateY(-165.55px);
            border: none;
            border-radius: 0;
            flex-grow: 0;
            width: 149px;
            background-color: transparent;
            z-index: 10;
            margin: 0 0 0 0;
            cursor: pointer;
            font-size: 5rem;
            display: none;
            align-items: center;
            justify-content: center;
            color: white;
            line-height: 0;
            transition: background-color 150ms ease-in-out;
            //height: 463.1px;
            opacity: 0;
            &:hover {
                opacity: 1;
            }
        }

        .react-multiple-carousel__arrow--right {
            
            right: -65px;
            &::before {
                content: "\203A";
                font-size: 50px;
                top: -10px;
            }
        }

        .react-multiple-carousel__arrow--left {
            
            left: -65px;
            &::before {
                content: "\2039";
                font-size: 50px;
                top: -10px;
            }
        }

        &.firststep {
            .react-multiple-carousel__arrow--left {
                opacity: 0;
                display:none;
                &::before {
                    content: "\2039";
                    font-size: 50px;
                    top: -10px;
                }
            }
        }
    }
    .react-multi-carousel-dot-list {
        position: absolute !important;
        bottom: unset !important;
        display: flex !important;
        top: -38px;
        left: unset !important;
        right: 0 !important;
        justify-content: flex-end !important;
        padding: 0 !important;
        margin: 0;
        list-style: none !important;
        text-align: center !important;
        height: 38px;
        li {
            display: flex;
            align-items: center;
            height: 100%;
        }
    }
    .react-multi-carousel-dot button {
        //position: absolute;
        display: inline-block!important;
        width: 74px!important;
        height: 7px!important;
        border-radius: 0!important;
        opacity: 0.43 !important;
        background-color: #FFFFFF;
        padding: 1px 1px 1px 1px!important;
        box-shadow: none!important;
        transition: background .5s!important;
        border: none !important;
        padding: 0!important;
        margin: 0 2px 0 0 !important;
        outline: 0;
        cursor: pointer;
        @media (min-width: 1600px) and (max-width: 1919.98px) {
            width: 60px!important;
            height: 5px!important;
        }
        @media (min-width: 1300px) and (max-width: 1599.98px) {
            width: 55px!important;
            height: 5px!important;
        }
        @media (min-width: 1080px) and (max-width: 1299.98px) {
            width: 50px!important;
            height: 5px!important;
        }
    }
    .react-multi-carousel-dot--active button {
        opacity: 1 !important;
        background: #FFFFFF !important;
    }
}

 .carousel-container-slide {
    display: flex;
    overflow: unset !important;
    justify-content: left;
    max-width: 100vw;
    margin: 0;
    position: relative;
    z-index: 1000 !important;
    
    .react-multi-carousel-item {
        transform-style: unset;
        &.active {
                margin-right: 8px;
        }
    }

    .react-multiple-carousel__arrow {
        border: none;
        border-radius: .5rem;
        flex-grow: 0;
        width: 28px;
        background-color: transparent;
        z-index: 10;
        margin: .25rem 0;
        cursor: pointer;
        font-size: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        line-height: 0;
        transition: background-color 150ms ease-in-out;
        min-width: 1rem !important;
        &:hover {
            background-color: transparent;
        }
    }

    .react-multiple-carousel__arrow--right {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        right: 15px;
        &::before {
            content: "\203A";
            font-size: 50px;
            top: -10px;
        }
    }

    .react-multiple-carousel__arrow--left {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        left: 15px;
        &::before {
            content: "\2039";
            font-size: 50px;
            top: -10px;
        }
    }
}

.swiper-slide {
    padding: 10px 0 !important;
    align-items: center !important;
    display: flex !important;
}