@import url('https://fonts.googleapis.com/css?family=Poppins:wght@400;500;700');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;700&display=swap');



.noselect {
  
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

body {
  background: #000000;
  min-height: 100vh;
  font-family: 'Poppins';
  overflow-x: hidden !important;
}

html {
	height: 100vh;
  scroll-behavior: smooth;
}







//pages
@import "header/header.scss";
@import "buttons/buttons.scss";
@import "cards/cardButton.scss";
@import "footer/footer.scss";
@import "pages/homepage.scss";
@import "pages/atalakitopage.scss";
@import "modals/modal.scss";
@import "dropdowns/dropdown.scss";
@import "carousel/carousel.scss";
@import "carousel/videocard.scss";
@import "pages/kapcsolatok.scss";
@import "layout/layout.scss";
@import "table/clienttable.scss";
@import "component/altalanos.scss";
@import "pages/trainerdetailpage.scss";



@import 'react-big-calendar/lib/sass/styles';
@import 'react-big-calendar/lib/addons/dragAndDrop/styles';

.animate-charcter
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #eae9f0 0%,
    #9566c8 29%,
    #3aff13 67%,
    #fff800 100%
  );
  
  background-size: auto auto;
  background-clip: border-box;
  color: #fff;
  background-clip: text;
  //text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 4s linear infinite;
  display: inline-block;
      font-size: 12px;
      font-style: bold;
      font-weight: 600;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

